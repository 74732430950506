import { DiscountType } from "../enums/Index";
import { Model } from "./Model";

export interface DiscountCode extends Model {
	allowedOnMondays: boolean;
	allowedOnTuesdays: boolean;
	allowedOnWednesdays: boolean;
	allowedOnThursdays: boolean;
	allowedOnFridays: boolean;
	allowedOnSaturdays: boolean;
	allowedOnSundays: boolean;
	code?: string;
	discountAmount: number;
	discountPercentage: number;
	discountCodeName?: string;
	/**
	 * Convert to a new Date object before use
	 */
	endDate: string;
	freeDelivery: boolean;
	/**
	 * Convert to a new Date object before use
	 */
	startDate: string;
	promoText?: string;
}

export class DiscountCode implements DiscountCode {
	constructor(data?: Partial<DiscountCode>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}