import { Model } from "./Model";

export interface OpeningTime extends Model {
    day: number;
    endTime: string;
	isActive: boolean;
    startTime: string;
    
    //menuCategory: MenuCategory | null;
}

export class OpeningTime implements OpeningTime {

	constructor(data?: Partial<OpeningTime>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}