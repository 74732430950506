import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ScrollToTop } from "src/app/helpers/Index";
import AppRoutes from "./AppRoutes";
import "./App.css";

export default class App extends React.Component {
	render() {
		return (
				<BrowserRouter>
					<ScrollToTop/>
					<AppRoutes/>
				</BrowserRouter>
		);
	}
}