import { RefreshAccessToken } from "src/app/interfaces/Index";
import { AppSettingsService, AuthService } from "src/app/services/Index";
import { HandleAuthTokenRefreshTimer } from "./HandleAuthTokenRefreshTimer";
import { Timeout } from "./Timeout";

export const HandleAuthTokenRefresh = async() => {
	const accessToken = await AppSettingsService.get("accessToken");
	const refreshToken = await AppSettingsService.get("refreshToken");
	const refreshAccessToken = new RefreshAccessToken(accessToken, refreshToken);

	try {
		const res = await AuthService.refreshToken(refreshAccessToken);
		const json = (await res.json());
		if (!res.ok) throw new Error(json);
		await AppSettingsService.set("accessToken", json.accessToken);
		await AppSettingsService.set("refreshToken", json.refreshToken);
		HandleAuthTokenRefreshTimer();
	} catch (e) {
		await Timeout(54000);
		await HandleAuthTokenRefresh();
	}
}