import React from "react";

type Props = {};
type State = {};
export class NotFound404 extends React.Component<Props, State> {
	render() {
		return (
			<div style={{textAlign: "center"}}>
			<br/>
			<h1>404 Not Found</h1>
			<h2>This is embarrassing... Looks like you might have taken a wrong turn!</h2>
			<br/>
			<img src="/assets/401.png" style={{width:"30vw"}}></img>
		</div>
		);
	}
}