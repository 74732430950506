import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { StaffMember } from "src/app/interfaces/Index";
import { StaffMemberService } from "src/app/services/Index";
import { colours } from "src/app/Styles";
import { InputSwitch } from "primereact/inputswitch";

type Props = {};
type State = {
	staff: StaffMember[];
	isLoading: boolean;
	selectedStaff: StaffMember;
	showStaffInfoModal: boolean;
	validationErrors: any;
};

export class Staff extends React.Component<Props, State> {
	dataTable: any;
	toast: any;

	constructor(props: Props) {
		super(props);
		this.state = { staff: [], isLoading: false, selectedStaff: new StaffMember(), showStaffInfoModal: false, validationErrors: null };
	}

	componentDidMount() {
		this.getStaffMembers();
	}

	discountCodeInfoFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => this.setState({ selectedStaff: new StaffMember(), showStaffInfoModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Save" onClick={() => this.saveStaffMember()}></Button>
			</>
		);
	}

	async getStaffMembers() {
		this.setState({ isLoading: true });
		const res = await (await StaffMemberService.getAll()).json();
		if (res && res.error) {
			this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
			this.setState({ isLoading: false });
		}
		this.setState({ staff: res, isLoading: false });
	}

	inputChange(e: any, name: string) {
		const val = e.value !== undefined ? e.value : (e.target !== undefined && e.target.value !== undefined) ? e.target.value : "";
		let selectedCoupon = { ...this.state.selectedStaff };
		// @ts-ignore // Required to be able to use a generic function to change the values
		selectedCoupon[label] = val;
		this.setState({ selectedStaff: selectedCoupon });
	}

	render() {
		return (
			<>
				<Row>
					<Col>
						<div className="card">
							<h5>Staff Members</h5>
							<Toolbar left={() => this.renderLeftToolbarButtons()} right={() => this.renderRightToolbarButtons()}></Toolbar>
							<DataTable loading={this.state.isLoading} ref={(el) => this.dataTable = el} scrollable scrollHeight="flex" style={{ height: "calc(100vh - 17.5rem)" }} value={this.state.staff}>
								<Column field="name" header="Name" sortable></Column>
								<Column field="emailAddress" header="Email" sortable></Column>
								<Column header="Status" body={(rowData: any) => this.renderStatusBadge(rowData)} sortable></Column>
								<Column style={{ width: 75 }} body={(rowData) => (
									<>
										<Button onClick={() => this.setState({ selectedStaff: rowData, showStaffInfoModal: true, validationErrors: null })} style={{ backgroundColor: colours.buttons.edit, borderColor: colours.buttons.edit, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
											<FontAwesomeIcon icon={faPencil} style={{ color: colours.white }} />
										</Button>
									</>
								)}></Column>
							</DataTable>
							<Dialog className="p-fluid" footer={() => this.discountCodeInfoFooter()} header="Coupon Details" modal onHide={() => this.setState({ showStaffInfoModal: false })} style={{ width: "75%" }} visible={this.state.showStaffInfoModal}>
								<Row>
									<Col>
										<div className="field">
											<label htmlFor="name">Name</label>
											<InputText autoFocus className={classNames({ "p-invalid": this.state.validationErrors !== null && this.state.validationErrors.name })} id="name" onChange={(e) => this.inputChange(e, "name")} required value={this.state.selectedStaff.name} />
											{this.state.validationErrors != null && this.state.validationErrors.name &&
												<small className="p-error">{this.state.validationErrors.name}</small>
											}
										</div>
									</Col>
									<Col>
										<div className="field">
											<label htmlFor="emailAddress">Email Address</label>
											<InputText autoFocus className={classNames({ "p-invalid": this.state.validationErrors !== null && this.state.validationErrors.emailAddress })} id="emailAddress" onChange={(e) => this.inputChange(e, "emailAddress")} required value={this.state.selectedStaff.emailAddress} />
											{this.state.validationErrors !== null && this.state.validationErrors.emailAddress &&
												<small className="p-error">{this.state.validationErrors.emailAddress}</small>
											}
										</div>
									</Col>
								</Row>
								{ this.state.selectedStaff.id == undefined &&
									<Row>
									<Col><div className="field">
											<label htmlFor="password">Password</label>
											<InputText autoFocus className={classNames({ "p-invalid": this.state.validationErrors !== null && this.state.validationErrors.emailAddress })} type="password" id="password" onChange={(e) => this.inputChange(e, "password")} required value={this.state.selectedStaff.password} />
											{this.state.validationErrors !== null && this.state.validationErrors.password &&
												<small className="p-error">{this.state.validationErrors.password}</small>
											}
										</div></Col>
										<Col><div className="field">
											<label htmlFor="confirmPassword">Confirm Password</label>
											<InputText autoFocus className={classNames({ "p-invalid": this.state.validationErrors !== null && this.state.validationErrors.confirmPassword })} type="password" id="confirmPassword" onChange={(e) => this.inputChange(e, "confirmPassword")} required value={this.state.selectedStaff.confirmPassword} />
											{this.state.validationErrors !== null && this.state.validationErrors.confirmPassword &&
												<small className="p-error">{this.state.validationErrors.confirmPassword}</small>
											}
										</div></Col>
								</Row>}
								<Row><br />	<div className="field col">
									<label htmlFor="permissions" style={{ fontWeight: "bold" }}>Permissions</label></div></Row>
								<Row>
									<Col>
										<div className="field col">
											<label htmlFor="isEnabled"><strong>Is Enabled</strong></label>
											<InputSwitch checked={this.state.selectedStaff.isEnabled} id="isEnabled" onChange={(e) => this.inputChange(e, "isEnabled")} />
										</div>
									</Col>
									<Col>
										<div className="field col">
											<label htmlFor="canManageOrders">Manage Orders</label>
											<InputSwitch checked={this.state.selectedStaff.canManageOrders} id="canManageOrders" onChange={(e) => this.inputChange(e, "canManageOrders")} />
										</div>
									</Col>
									<Col>
										<div className="field col">
											<label htmlFor="canManageMenu">Manage Menu</label>
											<InputSwitch checked={this.state.selectedStaff.canManageMenu} id="canManageMenu" onChange={(e) => this.inputChange(e, "canManageMenu")} />
										</div>
									</Col>

								</Row>
								<Row>
									<Col>
										<div className="field col">
											<label htmlFor="canManageCustomers">Manage Customers</label>
											<InputSwitch checked={this.state.selectedStaff.canManageCustomers} id="canManageCustomers" onChange={(e) => this.inputChange(e, "canManageCustomers")} />
										</div>
									</Col>
									<Col>
										<div className="field col">
											<label htmlFor="canManageRestaurant">Manage Restaurant</label>
											<InputSwitch checked={this.state.selectedStaff.canManageRestaurant} id="canManageRestaurant" onChange={(e) => this.inputChange(e, "canManageRestaurant")} />
										</div>
									</Col>
									<Col>
										<div className="field col">
											<label htmlFor="canManageStaff">Manage Staff</label>
											<InputSwitch checked={this.state.selectedStaff.canManageStaff} id="canManageStaff" onChange={(e) => this.inputChange(e, "canManageStaff")} />
										</div>
									</Col>
								</Row>
							</Dialog>
						</div>
					</Col>
				</Row>
				<Toast ref={(el) => this.toast = el}></Toast>
			</>
		);
	}

	renderLeftToolbarButtons() {
		return (
			<>
				<Button icon="pi pi-plus" label="New" style={{ backgroundColor: colours.buttons.add, borderColor: colours.buttons.add, marginRight: 10 }} onClick={() => this.setState({ selectedStaff: new StaffMember(), showStaffInfoModal: true, validationErrors: null })}></Button>
			</>
		);
	}

	renderRightToolbarButtons() {
		return (
			<>
				<Button icon="pi pi-download" label="Export" style={{ backgroundColor: colours.buttons.export, borderColor: colours.buttons.export, marginLeft: 10 }} onClick={() => this.dataTable.exportCSV()}></Button>
			</>
		);
	}

	renderStatusBadge(e: any) {

		if (e.isEnabled) {
			return <span className={`row-badge status-public`}>Active</span>
		}

		return <span className={`row-badge status-hidden`}>Disabled</span>
	}

	async saveStaffMember() {
		this.setState({ validationErrors: null });

		let staff = this.state.selectedStaff;

		const creatingNew = (staff.id === undefined || staff.id <= 0);
		const response = creatingNew ? await StaffMemberService.adminCreate(staff) : await StaffMemberService.updateSingleById(staff.id, staff);
		const responseDetails = await response.json();

		if (response.ok) {
			this.toast.show({ closable: false, detail: "Staff Member has been saved", life: 5000, severity: "success", summary: "Staff Member saved!" });
			this.setState({ showStaffInfoModal: false });
			this.getStaffMembers();
			return;
		}

		if (response.status === 422) {
			this.toast.show({ closable: false, detail: "Please fix the validation errors and try again", life: 5000, severity: "error", summary: "Validation Failed" });
			this.setState({ validationErrors: responseDetails.validationErrors });
			return;
		}

		this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
		console.log(responseDetails.error);
	}
}