import { Guid } from "guid-typescript";
import { DiscountCode } from "../interfaces/Index";
import { Service } from "./Service";

import { FetchInterceptor } from "../helpers/Index";

const endpoint = "DiscountCode";
export const DiscountCodeService = {
	endpoint,
	createSingle: async (data: DiscountCode) => await Service.createSingle(endpoint, data),
	deleteSingleById: async (id: number) => await Service.deleteSingleById(endpoint, id),
	getActive: async (endpoint: string) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Active`, { method: "GET"}),
	getAll: async () => await Service.getAll(endpoint),
	getSingleById: async (id: number) => await Service.getSingleById(endpoint, id),
	getSingleByLookupId: async (id: Guid) => await Service.getSingleByLookupId(endpoint, id),
	updateSingleById: async (id: number, data: DiscountCode) => await Service.updateSingleById(endpoint, id, data)
}