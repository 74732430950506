import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { NotFound404, Dashboard, Main, MenuItems, Delivery, MyAccount, MenuCategories, Menu, Restaurant, Orders, Coupons, Staff, Login, Unauthorised401} from "src/app/pages/Index";

export default class AppRoutes extends React.Component {
	render() {
		return (
			<Routes>
				{/* Admin Routes */}
				<Route caseSensitive={true} element={<Login/>} path={`/`}/>
				<Route caseSensitive={true} element={<Login/>} path={`/preview`}/>
				<Route caseSensitive={true} element={<Main/>} path={`/admin`}>
				<Route caseSensitive={true} element={<Dashboard/>} path=""/>
				<Route caseSensitive={true} element={<Coupons/>} path="coupons"/>
				<Route caseSensitive={true} element={<Delivery/>} path="delivery"/>
					<Route caseSensitive={true} element={<MyAccount/>} path="my-account"/>
					<Route caseSensitive={true} element={<Menu/>} path="menu"/>
					<Route caseSensitive={true} element={<MenuItems/>} path="menu/:lookupid"/>
					<Route caseSensitive={true} element={<MenuCategories/>} path="menu-categories"/>
					<Route caseSensitive={true} element={<Restaurant/>} path="restaurant"/>
					<Route caseSensitive={true} element={<Orders/>} path="orders"/>
					<Route caseSensitive={true} element={<Staff/>} path="staff"/>
					<Route caseSensitive={true} element={<Unauthorised401/>} path="401"/>
					<Route caseSensitive={true} element={<NotFound404/>} path="*"/>
				</Route>
				{/* <Route caseSensitive={true} element={<Navigate to={`/admin`}/>} path={`/*`}/> */}
			</Routes>
		);
	}
}