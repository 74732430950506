import { FetchInterceptor } from "../helpers/Index";
import { LoginDetails, RefreshAccessToken, SSOLoginDetails } from "../interfaces/Index";


const endpoint = "Auth";
export const AuthService = {
	endpoint,
	login: async (loginDetails: LoginDetails, group: string) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Login`, {body: JSON.stringify(loginDetails), headers: {"Content-Type": "application/json"}, method: "POST"}),
	loginFacebook: async (facebookLoginDetails: SSOLoginDetails, group: string) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/GetFacebookToken/${group}`, {body: JSON.stringify(facebookLoginDetails), headers: {"Content-Type": "application/json"}, method: "POST"}),
	loginGoogle: async (googleLoginDetails: SSOLoginDetails, group: string) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/GetGoogleToken/${group}`, {body: JSON.stringify(googleLoginDetails), headers: {"Content-Type": "application/json"}, method: "POST"}),
	refreshToken: async (refreshAccessToken: RefreshAccessToken) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/RefreshToken`, {body: JSON.stringify(refreshAccessToken), headers: {"Content-Type": "application/json"}, method: "POST"}),
	staffLogin: async (loginDetails: LoginDetails) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/StaffLogin`, {body: JSON.stringify(loginDetails), headers: {"Content-Type": "application/json"}, method: "POST"})
}