import { DiscountType } from "../enums/Index";
import { Model } from "./Model";

export interface DeliveryArea extends Model {
	outcode: string;
	isActive: boolean;
}

export class DeliveryArea implements DeliveryArea {
	constructor(data?: Partial<DeliveryArea>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}