import { Guid } from "guid-typescript";
import { EmailAttachment } from "../interfaces/Index";
import { Service } from "./Service";

const endpoint = "EmailAttachment";
export const EmailAttachmentService = {
	endpoint,
	createSingle: async (data: EmailAttachment) => await Service.createSingle(endpoint, data),
	deleteSingleById: async (id: number) => await Service.deleteSingleById(endpoint, id),
	getAll: async () => await Service.getAll(endpoint),
	getSingleById: async (id: number) => await Service.getSingleById(endpoint, id),
	getSingleByLookupId: async (id: Guid) => await Service.getSingleByLookupId(endpoint, id),
	updateSingleById: async (id: number, data: EmailAttachment) => await Service.updateSingleById(endpoint, id, data)
}