export interface LoginDetails {
	emailAddress: string;
	password: string;
}

export class LoginDetails implements LoginDetails {
	emailAddress = "";
	password = "";

	constructor(emailAddress?: string, password?: string) {
		if (emailAddress) this.emailAddress = emailAddress;
		if (password) this.password = password;
	}
}