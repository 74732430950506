import { faPencil, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Button } from "primereact/button";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Rating } from "primereact/rating";
import { SelectButton } from "primereact/selectbutton";
import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormatBooleanYesNo } from "src/app/helpers/FormatBooleanYesNo";
import { FormatNumber, GetPropertiesFromEnum } from "src/app/helpers/Index";
import { MenuItem, MenuCategory, MenuItemOption } from "src/app/interfaces/Index";
import { MenuCategoryService, DishService, FileService, ImageService } from "src/app/services/Index";
import { colours } from "src/app/Styles";
import "./Menu.css";
import { Calendar } from "primereact/calendar";
import { Navigate } from "react-router-dom";

type Props = {};
type State = {
	availableDays: any[];
	dishCategories: MenuCategory[];
	dishes: MenuItem[];
	isLoading: boolean;
	optionalExtras: MenuItemOption[];
	requiredOptions: MenuItemOption[];
	selectedCategory: MenuCategory;
	selectedCategories: MenuCategory[];
	selectedDish: MenuItem;
	selectedDishes: MenuItem[];
	selectedReq: MenuItemOption;
	showCategoryInfoModal: boolean;
	showDeleteCategoryModal: boolean;
	showDeleteCategorySelectionModal: boolean;
	showDeleteModal: boolean;
	showDeleteSelectionModal: boolean;
	showDishInfoModal: boolean;
	submitted: boolean;
	viewItem: boolean;
};
export class Menu extends React.Component<Props, State> {
	availDays = [
		{ code: "Sun", name: "Sunday" },
		{ code: "Mon", name: "Monday" },
		{ code: "Tue", name: "Tuesday" },
		{ code: "Wed", name: "Wednesday" },
		{ code: "Thu", name: "Thursday" },
		{ code: "Fri", name: "Friday" },
		{ code: "Sat", name: "Saturday" }
	];
	dataTable: any;
	toast: any;

	constructor(props: Props) {
		super(props);
		this.state = { availableDays: [], dishCategories: [], dishes: [], isLoading: false, optionalExtras: [], requiredOptions: [], selectedReq: new MenuItemOption(), selectedCategory: new MenuCategory(), selectedCategories: [], selectedDish: new MenuItem(), selectedDishes: [], showCategoryInfoModal:false, showDeleteCategoryModal:false, showDeleteCategorySelectionModal:false, showDeleteModal: false, showDeleteSelectionModal: false, showDishInfoModal: false, submitted: false, viewItem: false };
	}

	categoryChange(e: any) {
		let selectedDish = { ...this.state.selectedDish };
		// selectedDish.type = e.value;
		this.setState({ selectedDish });
	}

	componentDidMount() {
		this.getDishCategories();
		this.getDishes();
	}

	deleteDishFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="No" onClick={() => this.setState({ showDeleteModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Yes" onClick={(data) => this.deleteDishConfirm(data)}></Button>
			</>
		);
	}

	deleteDishesFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="No" onClick={() => this.setState({ showDeleteSelectionModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Yes" onClick={() => this.deleteSelectedDishesConfirm()}></Button>
			</>
		);
	}

	async deleteCategoryConfirm(rowData: any) {
		const res = await MenuCategoryService.deleteSingleById(this.state.selectedCategory.id);
		if (res && res.status !== 200) {
			this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
			this.setState({ isLoading: false });
		} else {
			this.setState({ isLoading: false, selectedCategory: new MenuCategory(), showDeleteModal: false })
			this.toast.show({ closable: false, detail: "Category Successfully Deleted", life: 5000, severity: "warn", summary: "Category Deleted" });
			this.getDishCategories();
		};
	}

	async deleteDishConfirm(rowData: any) {
		const res =(await DishService.deleteSingleById(this.state.selectedDish.id));
		console.log(res);
		console.log(res.status);
		if (res && res.status !== 200) {
			this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
			this.setState({ isLoading: false });
		} else {
			this.setState({ isLoading: false, selectedDish: new MenuItem(), showDeleteModal: false })
			this.toast.show({ closable: false, detail: "Menu Item Successfully Deleted", life: 5000, severity: "warn", summary: "Menu Item Deleted" });
			this.getDishes();
		};
	}

	deleteMenuCategoryFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="No" onClick={() => this.setState({ showDeleteModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Yes" onClick={(data) => this.deleteCategoryConfirm(data)}></Button>
			</>
		);
	}

	deleteSelectedDishesConfirm() { }

	dishInfoFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => this.setState({ selectedDish: new MenuItem(), showDishInfoModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label={this.state.selectedDish.id === undefined || this.state.selectedDish.id === -1 ? "Create" : "Save"} onClick={() => this.saveDish()}></Button>
			</>
		);
	}

	async getDishCategories() {
		this.setState({ isLoading: true });
		const res = await (await MenuCategoryService.getAll()).json();
		if (res && res.error) {
			this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
			this.setState({ isLoading: false });
		} else this.setState({ dishCategories: res, isLoading: false });
	}

	async getDishes() {
		this.setState({ isLoading: true });
		const res = await (await DishService.getAll()).json();
		if (res && res.error) {
			this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
			this.setState({ isLoading: false });
		} else this.setState({ dishes: res, isLoading: false });
	}

	inputCategoryChange(e: any, name: string) {
		const val = e.value !== undefined ? e.value : (e.target !== undefined && e.target.value !== undefined) ? e.target.value : "";

		if (name === "offerDescription" && val.length > 10) {
			e.preventDefault();
			return false;
		}

		let selectedCategory = { ...this.state.selectedCategory };
		// @ts-ignore // Required to be able to use a generic function to change the values
		selectedCategory[label] = val;
		this.setState({ selectedCategory });
	}

	inputChange(e: any, name: string) {
		const val = e.value !== undefined ? e.value : (e.target !== undefined && e.target.value !== undefined) ? e.target.value : "";

		if (name === "offerDescription" && val.length > 10) {
			e.preventDefault();
			return false;
		}

		let selectedDish = { ...this.state.selectedDish };
		// @ts-ignore // Required to be able to use a generic function to change the values
		selectedDish[label] = val;
		this.setState({ selectedDish });
	}

	menuCategoryInfoFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => this.setState({ selectedCategory: new MenuCategory(), showCategoryInfoModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label={this.state.selectedCategory.id === undefined || this.state.selectedCategory.id === -1 ? "Create" : "Save"} onClick={() => this.saveCategory()}></Button>
			</>
		);
	}

	onRequiredEdit(e: any) {
	}

	render() {
		return (
			<>
				<Row>
					<Col>
						<div className="card">
							<h5>Menu</h5>
							<TabView className="tabview-header-icon">
							<TabPanel header="Categories" leftIcon="pi pi-book">
							<Toolbar left={() => this.renderLeftToolbarButtonsCategory()} right={() => this.renderRightToolbarButtonsCategory()}></Toolbar>
							<DataTable stripedRows loading={this.state.isLoading} filterDisplay="row" onSelectionChange={(e) => this.setState({ selectedCategories: e.value })} ref={(el) => this.dataTable = el} scrollable scrollHeight="flex" selection={this.state.selectedCategories} style={{ height: "calc(100vh - 17.5rem)" }} value={this.state.dishCategories}>
								<Column field="name" header="Name" sortable filter></Column>
								<Column header="Visible on Site" body={(rowData: any) => this.renderVisibleStatusBadge(rowData)} sortable></Column>
								<Column style={{ width: 75 }} body={(rowData) => (
									<div className="actions">
										<Button onClick={() => this.renderCategoryDialog(rowData)} style={{ backgroundColor: colours.buttons.edit, borderColor: colours.buttons.edit, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
											<FontAwesomeIcon icon={faPencil} style={{ color: colours.white }} />
										</Button>
										<Button onClick={() => this.setState({ selectedCategory: rowData, showDeleteModal: true })} style={{ backgroundColor: colours.buttons.delete, borderColor: colours.buttons.delete, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
											<FontAwesomeIcon icon={faTrashAlt} style={{ color: colours.black }} />
										</Button>
									</div>
								)}></Column>
							</DataTable>
								</TabPanel>
						<TabPanel header="Menu Items" leftIcon="pi pi-box">
							<Toolbar left={() => this.renderLeftToolbarButtons()} right={() => this.renderRightToolbarButtons()}></Toolbar>
							<DataTable stripedRows loading={this.state.isLoading} filterDisplay="row" onSelectionChange={(e) => this.setState({ selectedDishes: e.value })} ref={(el) => this.dataTable = el} scrollable scrollHeight="flex" selection={this.state.selectedDishes} style={{ height: "calc(100vh - 17.5rem)" }} value={this.state.dishes}>
							<Column field="picture" header="Picture" body={(rowData)=> this.renderPicture(rowData)}></Column>
								<Column field="name" header="Name" sortable filter></Column>
								<Column field="price" header="Price" dataType="numeric" body={(rowData) => FormatNumber(rowData.price,true)} sortable></Column>
								<Column header="Available" body={(rowData: any) => this.renderStatusBadge(rowData)} sortable></Column>
								<Column header="Visible on Site" body={(rowData: any) => this.renderVisibleStatusBadge(rowData)} sortable></Column>
								<Column style={{ width: 75 }} body={(rowData) => (
									<div className="actions">
										<Button onClick={() => this.renderDialog(rowData)} style={{ backgroundColor: colours.buttons.edit, borderColor: colours.buttons.edit, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
											<FontAwesomeIcon icon={faPencil} style={{ color: colours.white }} />
										</Button>
										<Button onClick={() => this.setState({ selectedDish: rowData, showDeleteModal: true })} style={{ backgroundColor: colours.buttons.delete, borderColor: colours.buttons.delete, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
											<FontAwesomeIcon icon={faTrashAlt} style={{ color: colours.black }} />
										</Button>
									</div>
								)}></Column>
							</DataTable>
							</TabPanel>
							</TabView>
							<Dialog className="p-fluid" footer={() => this.menuCategoryInfoFooter()} header="Category Details" modal onHide={() => this.setState({ showCategoryInfoModal: false })} style={{ width: "75%", height: "85vh" }} visible={this.state.showCategoryInfoModal}>
								<TabView className="tabview-header-icon">
									<TabPanel header="Basic Information" leftIcon="pi pi-info-circle">
										<Row>
											<div className="field">
												<label htmlFor="name">Name</label>
												<InputText className={classNames({ "p-invalid": this.state.submitted && !this.state.selectedCategory.name })} id="name" onChange={(e) => this.inputCategoryChange(e, "name")} readOnly={false} disabled={false} required value={this.state.selectedCategory.name} />
												{this.state.submitted && !this.state.selectedCategory.name &&
													<small className="p-invalid">Name is required.</small>
												}
											</div>
										</Row>
										<Row>
											<div className="field">
												<label htmlFor="description">More Information</label>
												<InputTextarea id="description" onChange={(e) => this.inputCategoryChange(e, "description")} required rows={3} value={this.state.selectedCategory.moreInformation} />
											</div>
										</Row>
										<Row>
											<Col>
												<div className="field">
													<label htmlFor="rank">Rank</label>
													<InputNumber id="rank" onChange={(e) => this.inputCategoryChange(e, "rank")} required value={this.state.selectedCategory.rank} />
												</div>
											</Col>
											<Col>
												<div className="field">
													<label htmlFor="displayOnSite" className="switchSpacing">Display on site</label>
													<InputSwitch id="displayOnSite" onChange={(e) => this.inputCategoryChange(e, "displayOnSite")} required checked={this.state.selectedCategory.displayOnSite} />
												</div>
											</Col>
										</Row>
									</TabPanel>
									<TabPanel header="Image" leftIcon="pi pi-image">
									<Row style={{marginBottom:10}}><text>Photo</text></Row>
										<Row>
											<Col>
												<div style={{backgroundColor: "#EDF1F5", width:356, height:356, padding:25, borderStyle: "dashed", borderColor: "#708DA8", borderWidth:3, borderRadius:10}}>{this.state.selectedCategory.pictureUrl &&
													<img alt={this.state.selectedCategory.pictureUrl} className="mt-0 mx-auto block" src={this.state.selectedCategory.pictureUrl} width={300} />
												}</div>
											</Col>
											<Col>
												<div className="field" style={{ marginBottom: 10 }}>
													<div style={{marginBottom:20}}><text>Photos help customers decide what to order and can increase sales.</text></div>
													<div style={{marginBottom:20}}><text>For the best results we recommend using an image with a 1:1 ratio (square)</text></div>
													<div style={{marginBottom:20}}><text>File requirement: JPG, PNG, GIF, or WEBP up to 10MB. Minimum pixels required: 320 for width and height.</text></div>
													<FileUpload accept="image/*" auto mode="basic" customUpload name="pictureUrl" style={{ marginBottom: 10 }} uploadHandler={(data) => this.uploadImage(data, "pictureUrl")}></FileUpload>
												</div>
											</Col>
										</Row>
									</TabPanel>
								</TabView>
							</Dialog>
							<Dialog footer={() => this.deleteMenuCategoryFooter()} header="Confirm" modal onHide={() => this.setState({ showDeleteModal: false })} style={{ width: 550 }} visible={this.state.showDeleteModal}>
								<div className="d-flex align-items-center justify-content-center">
									<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem", marginRight: "0.5rem" }} />
									{this.state.selectedCategory &&
										<span>Are you sure you want to archive <b>{this.state.selectedCategory.name}</b>?</span>
									}
								</div>
							</Dialog>
							<Dialog className="p-fluid" footer={() => this.dishInfoFooter()} header="Menu Item Detail" modal onHide={() => this.setState({ showDishInfoModal: false })} style={{ width: "75%", height: "85vh" }} visible={this.state.showDishInfoModal}>
								<TabView className="tabview-header-icon">
									<TabPanel header="Basic Information" leftIcon="pi pi-info-circle">
										<Row>
											<div className="field">
												<label htmlFor="name">Name</label>
												<InputText autoFocus className={classNames({ "p-invalid": this.state.submitted && !this.state.selectedDish.name })} id="name" onChange={(e) => this.inputChange(e, "name")} required value={this.state.selectedDish.name} />
												{this.state.submitted && !this.state.selectedDish.name &&
													<small className="p-invalid">Name is required.</small>
												}
											</div>
										</Row>
										<Row>
						<Col>
							<div className="field" style={{ marginBottom: 10 }}>
								<label htmlFor="cat">Dish Category</label>
								<Dropdown onChange={(e) => this.setState({selectedDish: {...this.state.selectedDish, menuCategoryId: e.value}})} options={this.state.dishCategories} style={{width: "100%"}} value={this.state.selectedDish.menuCategoryId} optionLabel="name" optionValue="id"/>
							</div>
						</Col>
					</Row>
									</TabPanel>
								</TabView>
							</Dialog>
							<Dialog footer={() => this.deleteDishFooter()} header="Confirm" modal onHide={() => this.setState({ showDeleteModal: false })} style={{ width: 550 }} visible={this.state.showDeleteModal}>
								<div className="d-flex align-items-center justify-content-center">
									<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem", marginRight: "0.5rem" }} />
									{this.state.selectedDish &&
										<span>Are you sure you want to archive <b>{this.state.selectedDish.name}</b>?</span>
									}
								</div>
							</Dialog>
						</div>
					</Col>
				</Row>
				<Toast ref={(el) => this.toast = el}></Toast>
				{this.state.viewItem &&
				<Navigate replace={true} to={`${this.state.selectedDish?.lookupId}`}/>
	}
			</>
		);
	}

	renderCategoryDialog(rowData: any) {
		this.setState({ selectedCategory: rowData, showCategoryInfoModal: true })
	}

	renderDialog(rowData: any) {
		this.setState({viewItem:true});
		let allReq = JSON.parse(rowData.requiredOptions);
		let allOpt = JSON.parse(rowData.optionalExtras);
		this.setState({ selectedDish: rowData, requiredOptions: allReq, optionalExtras: allOpt, showDishInfoModal: true })
	}

	renderLeftToolbarButtons() {
		return (
			<>
				<Button icon="pi pi-plus" label="New" style={{ backgroundColor: colours.buttons.add, borderColor: colours.buttons.add, marginRight: 10 }} onClick={() => this.setState({ selectedDish: new MenuItem(), showDishInfoModal: true })}></Button>
			</>
		);
	}

	renderLeftToolbarButtonsCategory() {
		return (
			<>
				<Button icon="pi pi-plus" label="New" style={{ backgroundColor: colours.buttons.add, borderColor: colours.buttons.add, marginRight: 10 }} onClick={() => this.setState({ selectedCategory: new MenuCategory(), showCategoryInfoModal: true })}></Button>
			</>
		);
	}

	renderRightToolbarButtons() {
		return (
			<>
				<Button icon="pi pi-download" label="Export" style={{ backgroundColor: colours.buttons.export, borderColor: colours.buttons.export, marginLeft: 10 }} onClick={() => this.dataTable.exportCSV()}></Button>
			</>
		);
	}

	renderRightToolbarButtonsCategory() {
		return (
			<>
				<Button icon="pi pi-download" label="Export" style={{ backgroundColor: colours.buttons.export, borderColor: colours.buttons.export, marginLeft: 10 }} onClick={() => this.dataTable.exportCSV()}></Button>
			</>
		);
	}

	renderStatusBadge(e: any) {
		if (e.availableToBuy) {
			return <span className={`row-badge status-public`}>Available</span>
		} else {
			return <span className={`row-badge status-hidden`}>Not Available</span>
		}
	}

	renderPicture(e: any) {
		return   <img alt={e.name} src={`${e.picture}`} width="32" />
	}

	renderVisibleStatusBadge(e: any) {
		if (e.displayOnSite) {
			return <span className={`row-badge status-public`}>Visible</span>
		} else {
			return <span className={`row-badge status-hidden`}>Hidden</span>
		}
	}

	async saveCategory() {
		await this.setState({ isLoading: true });
		if (this.state.selectedCategory.id === undefined || this.state.selectedCategory.id === -1) { // Create new
			const res = await (await MenuCategoryService.createSingle(this.state.selectedCategory)).json();
			if (res && res.error) {
				this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
				this.setState({ isLoading: false });
			} else {
				this.setState({ isLoading: false, selectedCategory: res, showCategoryInfoModal: false, viewItem:true })
				this.getDishCategories();
			};
		} else { // Save existing
			const res = await (await MenuCategoryService.updateSingleById(this.state.selectedCategory.id, this.state.selectedCategory)).json();
			if (res && res.error) {
				this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
				this.setState({ isLoading: false });
			} else {
				this.setState({ isLoading: false, selectedCategory: new MenuCategory(), showCategoryInfoModal: false })
				this.getDishCategories();
			};
		}
	}

	async saveDish() {
		await this.setState({ isLoading: true });
		if (this.state.selectedDish.id === undefined || this.state.selectedDish.id === -1) { // Create new
			const res = await (await DishService.chefCreate(this.state.selectedDish)).json();
			if (res && res.error) {
				this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
				this.setState({ isLoading: false });
			} else {
				this.setState({ isLoading: false, selectedDish: res, showDishInfoModal: false, viewItem:true })
				
			};
		} else { // Save existing
			const res = await (await DishService.updateSingleById(this.state.selectedDish.id, this.state.selectedDish)).json();
			if (res && res.error) {
				this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
				this.setState({ isLoading: false });
			} else {
				this.setState({ isLoading: false, selectedDish: new MenuItem(), showDishInfoModal: false })
				this.getDishes();
			};
		}
	}

	async uploadImage(data: any, property: string) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", data);
		uploadData.append("mimeTypeKey", "Images");
		const response = await (await FileService.upload(uploadData)).json();
		let tempDish = { ...this.state.selectedDish };
		// @ts-ignore // Required to be able to change the values
		tempDish[property] = `${process.env.REACT_APP_SERVER_STORAGEURL}/${containerName}/${response[0].location}`;
		await this.setState({ selectedDish: tempDish });
	}

	async removeImageBackground(data: any, property: string) {
		const formData = new FormData();
		formData.append("image_file", data.files[0]);
		const response = await (await ImageService.removeBackground(formData)).blob();
		await this.uploadImage(response, property);
	}

	textEditor(options:any){
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };
}