import { ChartDataset } from "./ChartDataset";

export interface ChartData{
	labels: string[];
	dataset: ChartDataset[];
}

export class ChartData implements ChartData {
	constructor(data?: Partial<ChartData>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}