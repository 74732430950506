export interface ChartDataset{
	label: string,
    data: number[],
    fill: boolean,
    tension: number,
	borderColor: string,
}

export class ChartDataset implements ChartDataset {
	constructor(data?: Partial<ChartDataset>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}