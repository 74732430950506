import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { OpeningTime, RestaurantSettings } from "src/app/interfaces/Index";
import { RestaurantSettingsService, FileService, OpeningTimeService } from "src/app/services/Index";
import { colours } from "src/app/Styles";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { faPencil, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";

type Props = {};
type State = {
	chefProfile: RestaurantSettings;
	chefProfileOriginal: RestaurantSettings;
	countries: string[];
	isLoading: boolean;
	openingTime: OpeningTime[];
	selectedDay: string;
	selectedOpeningTime: OpeningTime;
	showDeleteModal: boolean;
	showDeleteSelectionModal: boolean;
	showOpeningTimeModal: boolean;
};
export class Restaurant extends React.Component<Props, State> {
	availDays = [
		{ value: "0", label: "Sunday" },
		{ value: "1", label: "Monday" },
		{ value: "2", label: "Tuesday" },
		{ value: "3", label: "Wednesday" },
		{ value: "4", label: "Thursday" },
		{ value: "5", label: "Friday" },
		{ value: "6", label: "Saturday" }
	];
	toast: any;

	constructor(props: Props) {
		super(props);
		this.state = { chefProfile: new RestaurantSettings(), chefProfileOriginal: new RestaurantSettings(), countries: ["United Kingdom"], isLoading: false, openingTime: [], selectedDay: "", selectedOpeningTime: new OpeningTime, showDeleteModal: false, showDeleteSelectionModal: false, showOpeningTimeModal: false };
	}

	componentDidMount() {
		this.getMyKitchen();
	}

	deleteOpeningTimeFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="No" onClick={() => this.setState({ showDeleteModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Yes" onClick={(data) => this.deleteOpeningTimeConfirm(data)}></Button>
			</>
		);
	}

	async deleteOpeningTimeConfirm(rowData: any) {
		const res = await (await OpeningTimeService.deleteSingleById(this.state.selectedOpeningTime.id)).json();
		if (res && res.error) {
			this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
			this.setState({ isLoading: false });
		} else {
			this.setState({ isLoading: false, selectedOpeningTime: new OpeningTime(), showDeleteModal: false })
			this.toast.show({ closable: false, detail: "MenuItem Successfully Archived", life: 5000, severity: "warn", summary: "MenuItem Archived" });
			this.getMyKitchen();
		};
	}

	async getMyKitchen() {
		const res = await (await RestaurantSettingsService.details()).json();
		const avRes = await (await OpeningTimeService.getAll()).json();
		if (res && res.error) this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
		else this.setState({ chefProfile: res, chefProfileOriginal: res, openingTime: avRes });
	}

	inputChange(e: any, label: string) {
		const val = e.value !== undefined ? e.value : (e.target !== undefined && e.target.value !== undefined) ? e.target.value : "";
		let chefProfile = { ...this.state.chefProfile };
		// @ts-ignore // Required to be able to use a generic function to change the values
		chefProfile[label] = val;
		this.setState({ chefProfile });
	}

	openingTimeInfoFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => this.setState({ selectedOpeningTime: new OpeningTime(), showOpeningTimeModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label={this.state.selectedOpeningTime.id === undefined || this.state.selectedOpeningTime.id === -1 ? "Create" : "Save"} onClick={() => this.saveOpeningTime()}></Button>
			</>
		);
	}

	render() {
		return (
			<>
				<div className="card p-fluid" style={{ paddingBottom: "0.5rem", paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "1rem" }}>
					<h5>Restaurant Settings</h5>
					<TabView className="tabview-header-icon">
						<TabPanel header="Basic" leftIcon="pi pi-info-circle">
							<Row>
								<Col>
									<div className="field">
										<label htmlFor="name">Restaurants Name</label>
										<InputText id="name" onChange={(e) => this.inputChange(e, "name")} required value={this.state.chefProfile.name || ""} />
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="field">
										<label htmlFor="description">Restaurants Description</label>
										<InputTextarea id="description" onChange={(e) => this.inputChange(e, "description")} required rows={3} value={this.state.chefProfile.description || ""} />
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="field">
										<label htmlFor="emailAddress">Email Address</label>
										<InputText id="emailAddress" onChange={(e) => this.inputChange(e, "emailAddress")} required value={this.state.chefProfile.emailAddress || ""} />
									</div>
								</Col>
								<Col>
									<div className="field">
										<label htmlFor="phoneNumber">Phone Number</label>
										<InputText id="phoneNumber" onChange={(e) => this.inputChange(e, "phoneNumber")} required value={this.state.chefProfile.phoneNumber || ""} />
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="field">
										<label htmlFor="minimumOrderValue">Minimum Order Value</label>
										<InputNumber id="minimumOrderValue" onChange={(e) => this.inputChange(e, "minimumOrderValue")} required value={this.state.chefProfile.minimumOrderValue || 0} />
									</div>
								</Col>
								<Col>
									<div className="field">
										<label htmlFor="estimatedDeliveryTime">Estimated Delivery (Minutes)</label>
										<InputText id="estimatedDeliveryTime" onChange={(e) => this.inputChange(e, "estimatedDeliveryTime")} required value={this.state.chefProfile.estimatedDeliveryTime || ""} />
									</div>
								</Col>
								<Col>
									<div className="field">
										<label htmlFor="estimatedCollectionTime">Estimated Collection (Minutes)</label>
										<InputText id="estimatedCollectionTime" onChange={(e) => this.inputChange(e, "estimatedCollectionTime")} required value={this.state.chefProfile.estimatedCollectionTime || ""} />
									</div>
								</Col>
							</Row>

						</TabPanel>
						<TabPanel header="Images" leftIcon="pi pi-image">
							<Row>
								<Col>
									<div className="field">
										<label htmlFor="picture" style={{ width: "100%" }}>Restaurants Profile Image</label>
										<img id="picture" src={this.state.chefProfile.picture} style={{ height: 90, marginBottom: 10, marginTop: 10 }}></img>
										<FileUpload accept="image/*" auto customUpload name="picture" style={{ marginBottom: 10 }} uploadHandler={(data) => this.uploadImage(data, "picture")} mode="basic"></FileUpload>
									</div>
								</Col>
							</Row>
						</TabPanel>
						<TabPanel header="Address" leftIcon="pi pi-building">
							<Row>
								<Col xs={5}>
									<div className="field">
										<label htmlFor="addressLine1">Address Line 1</label>
										<InputText id="addressLine1" onChange={(e) => this.inputChange(e, "addressLine1")} required value={this.state.chefProfile.addressLine1 || ""} />
									</div>
								</Col>
								<Col xs={5}>
									<div className="field">
										<label htmlFor="addressLine2">Address Line 2</label>
										<InputText id="addressLine2" onChange={(e) => this.inputChange(e, "addressLine2")} required value={this.state.chefProfile.addressLine2 || ""} />
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="field">
										<label htmlFor="town">Town</label>
										<InputText id="town" onChange={(e) => this.inputChange(e, "town")} required value={this.state.chefProfile.town || ""} />
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="field">
										<label htmlFor="postcode">Postcode</label>
										<InputText id="postcode" onChange={(e) => this.inputChange(e, "postcode")} readOnly={true} disabled={true} value={this.state.chefProfile.postcode || ""} />
									</div>
								</Col>
							</Row>
						</TabPanel>
						<TabPanel header="Payment" leftIcon="pi pi-wallet">
							<Row>
								<Col>
									<div className="field col">
										<label htmlFor="allowCashOnCollection">Allow Cash on Collection</label>
										<InputSwitch checked={this.state.chefProfile.allowCashOnCollection} id="allowCashOnCollection" onChange={(e) => this.inputChange(e, "allowCashOnCollection")} />
									</div>
								</Col>
								<Col>
									<div className="field col">
										<label htmlFor="allowCashOnDelivery">Allow Cash</label>
										<InputSwitch checked={this.state.chefProfile.allowCashOnDelivery} id="allowCashOnDelivery" onChange={(e) => this.inputChange(e, "allowCashOnDelivery")} />
									</div>
								</Col>
								<Col>
									<div className="field col">
										<label htmlFor="allowOnlinePayments">Allow Online</label>
										<InputSwitch checked={this.state.chefProfile.allowOnlinePayments} id="allowOnlinePayments" onChange={(e) => this.inputChange(e, "allowOnlinePayments")} />
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="field col">
										<label htmlFor="guestAllowCashOnCollection">Allow Cash on Collection (Guest)</label>
										<InputSwitch checked={this.state.chefProfile.guestAllowCashOnCollection} id="guestAllowCashOnCollection" onChange={(e) => this.inputChange(e, "guestAllowCashOnCollection")} />
									</div>
								</Col>
								<Col>
									<div className="field col">
										<label htmlFor="guestAllowCashOnDelivery">Allow Cash (Guest)</label>
										<InputSwitch checked={this.state.chefProfile.guestAllowCashOnDelivery} id="guestAllowCashOnDelivery" onChange={(e) => this.inputChange(e, "guestAllowCashOnDelivery")} />
									</div>
								</Col>
								<Col>
									<div className="field col">
										<label htmlFor="guestAllowOnlinePayments">Allow Online (Guest)</label>
										<InputSwitch checked={this.state.chefProfile.guestAllowOnlinePayments} id="guestAllowOnlinePayments" onChange={(e) => this.inputChange(e, "guestAllowOnlinePayments")} />
									</div>
								</Col>
							</Row>
						</TabPanel>
						<TabPanel header="Hours" leftIcon="pi pi-clock">
							<Row>
								<Col>
									<div className="field">
										{this.renderAvailableDays()}
									</div>
								</Col>
							</Row>
						</TabPanel>
						<TabPanel header="Allergen" leftIcon="pi pi-exclamation-triangle">
							<Row>
								<Col>
									<div className="field">
										<label htmlFor="allergenDetails">Allergen Description</label>
										<InputTextarea id="allergenDetails" onChange={(e) => this.inputChange(e, "allergenDetails")} required rows={3} value={this.state.chefProfile.allergenDetails || ""} />
									</div>
								</Col>
							</Row>
						</TabPanel>
					</TabView>
					<Row>
						<Col>
							<div className="field" style={{ marginBottom: 10, textAlign: "right" }}>
								<Button disabled={JSON.stringify(this.state.chefProfile) === JSON.stringify(this.state.chefProfileOriginal) || this.state.isLoading === true} iconPos="right" label="Discard" style={{ backgroundColor: colours.buttons.cancel, borderColor: colours.buttons.cancel, marginRight: 10, width: 150 }} onClick={() => this.setState({ chefProfile: this.state.chefProfileOriginal })}></Button>
								<Button disabled={JSON.stringify(this.state.chefProfile) === JSON.stringify(this.state.chefProfileOriginal)} iconPos="right" label="Save" loading={this.state.isLoading} style={{ backgroundColor: colours.buttons.save, borderColor: colours.buttons.save, width: 150 }} onClick={() => this.saveChanges()}></Button>
							</div>
						</Col>
					</Row>
				</div>
				<Toast ref={(el) => this.toast = el}></Toast>
				<Dialog className="p-fluid" footer={() => this.openingTimeInfoFooter()} header="Opening Time Details" modal onHide={() => this.setState({ showOpeningTimeModal: false })} style={{ width: "75%", height: "85vh" }} visible={this.state.showOpeningTimeModal}>
					<Row>
						<Col>
							<div className="field" style={{ marginBottom: 10 }}>
								<label htmlFor="day">Opening Time Day</label>
								<Dropdown onChange={(e) => this.setState({ selectedOpeningTime: { ...this.state.selectedOpeningTime, day: e.value } })} options={this.availDays} optionValue='value' optionLabel="label" style={{ width: "100%" }} value={this.state.selectedOpeningTime.day} defaultValue={this.state.selectedOpeningTime.day} />
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="field" style={{ marginBottom: 10 }}>
								<label htmlFor="startTime">Opening Time Start</label>
								<InputText id="startTime" onChange={(e) => this.inputChange(e, "startTime")} required value={this.state.selectedOpeningTime.startTime} />
							</div>
						</Col>
						<Col>
							<div className="field" style={{ marginBottom: 10 }}>
								<label htmlFor="endTime">Opening Time End</label>
								<InputText id="endTime" onChange={(e) => this.inputChange(e, "endTime")} required value={this.state.selectedOpeningTime.endTime} />
							</div>
						</Col>
					</Row>
				</Dialog>
				<Dialog footer={() => this.deleteOpeningTimeFooter()} header="Confirm" modal onHide={() => this.setState({ showDeleteModal: false })} style={{ width: 550 }} visible={this.state.showDeleteModal}>
					<div className="d-flex align-items-center justify-content-center">
						<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem", marginRight: "0.5rem" }} />
						{this.state.selectedOpeningTime &&
							<span>Are you sure you want to archive <b>{this.state.selectedOpeningTime.startTime}</b> opening time for <b>{this.state.selectedDay}</b>?</span>
						}
					</div>
				</Dialog>
			</>
		);
	}

	renderAvailableDays() {
		return (
			<>
				<Row>
					<Col>
						<DataTable id="openingTime" scrollable scrollHeight="350px" style={{ marginBottom: 10 }} value={this.state.openingTime}>
							<Column header="Day" body={(rowData: any) => this.renderDay(rowData)} sortable></Column>
							<Column field="startTime" header="Start Time" sortable></Column>
							<Column field="endTime" header="End Time" sortable></Column>
							<Column style={{ width: 75 }} body={(rowData) => (
								<div className="actions">
									<Button onClick={() => this.renderDialog(rowData)} style={{ backgroundColor: colours.buttons.edit, borderColor: colours.buttons.edit, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
										<FontAwesomeIcon icon={faPencil} style={{ color: colours.white }} />
									</Button>
									<Button onClick={() => this.setState({ selectedOpeningTime: rowData, showDeleteModal: true })} style={{ backgroundColor: colours.buttons.delete, borderColor: colours.buttons.delete, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
										<FontAwesomeIcon icon={faTrashAlt} style={{ color: colours.black }} />
									</Button>
								</div>
							)}></Column>
						</DataTable>
					</Col>
				</Row>
			</>
		)
		// if (!this.state.chefProfile) return;
		// return this.state.chefProfile.availabilityArray.map((data: any, i: number) => {
		// 	return (
		// 		<Row key={i}>
		// 			<Col style={{alignItems: "center", display: "flex", height: 30}}>
		// 				<div style={{fontSize: 14, width: 85}}>{data.day}</div>
		// 				<InputSwitch checked={data.isAvailable} onChange={(e) => this.updateAvailableDays(i, e.value)} style={{marginRight: 15}}></InputSwitch>
		// 				<Calendar disabled={data.isAvailable === false} hourFormat="24" onChange={(e) => this.updateAvailableDayTime(i, "startTime", e.value)} showTime style={{height: 28, width: 48, marginRight: 5}} timeOnly value={new Date(data.startTime)}></Calendar>
		// 				-
		// 				<Calendar disabled={data.isAvailable === false} hourFormat="24" onChange={(e) => this.updateAvailableDayTime(i, "endTime", e.value)} showTime style={{height: 28, width: 48, marginLeft: 5}} timeOnly value={new Date(data.endTime)}></Calendar>
		// 			</Col>
		// 		</Row>
		// 	);
		// });
	}

	renderDay(e: any) {
		return this.availDays.find(x => x.value == e.day)?.label;

	}

	renderDialog(rowData: any) {
		this.setState({ selectedDay: this.renderDay(rowData)!, selectedOpeningTime: rowData, showOpeningTimeModal: true })
	}

	renderFileStatus(file?: string) {
		if (file === null)
			return <Message severity="error" text="File not yet uploaded." />
		else
			return <Message severity="success" text="File uploaded." />
	}


	async saveChanges() {
		await this.setState({ isLoading: true });
		const res = await (await RestaurantSettingsService.updateSingleById(1, this.state.chefProfile)).json();
		if (res && res.error) {
			this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
			this.setState({ isLoading: false });
		} else {
			this.toast.show({ closable: false, detail: "Changes saved successfully", life: 5000, severity: "success" });
			this.setState({ chefProfile: res, chefProfileOriginal: res, isLoading: false });
		}
	}

	async saveOpeningTime() {
		await this.setState({ isLoading: true });
		const res = await (await OpeningTimeService.updateSingleById(1, this.state.selectedOpeningTime)).json();
		if (res && res.error) {
			this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
			this.setState({ isLoading: false });
		} else {
			this.toast.show({ closable: false, detail: "Changes saved successfully", life: 5000, severity: "success" });
			this.setState({ isLoading: false, showOpeningTimeModal: false });
			this.getMyKitchen();
		}
	}

	async uploadImage(data: any, property: string) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", data.files[0]);
		uploadData.append("mimeTypeKey", "Images");
		const response = await (await FileService.upload(uploadData)).json();
		let tempChef = { ...this.state.chefProfile };
		// @ts-ignore // Required to be able to change the values
		tempChef[property] = `${process.env.REACT_APP_SERVER_STORAGEURL}/${containerName}/${response[0].location}`;
		await this.setState({ chefProfile: tempChef });
	}

	updateAvailableDayTime(i: number, property: string, value: any) {
		// const newAvailDays = [...this.state.chefProfile.availabilityArray];
		// const day = {...newAvailDays[i]};
		// // @ts-ignore // Required to be able to change the values
		// day[property] = value;
		// newAvailDays[i] = day;
		// this.setState({chefProfile: {...this.state.chefProfile, availabilityArray: newAvailDays}});
	}

	updateAvailableDays(i: number, value: boolean) {
		// const newAvailDays = [...this.state.chefProfile.availabilityArray];
		// const day = {...newAvailDays[i]};
		// day.isAvailable = value;
		// newAvailDays[i] = day;
		// this.setState({chefProfile: {...this.state.chefProfile, availabilityArray: newAvailDays}});
	}
}