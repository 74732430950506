import { OrderPaymentMethod, OrderStatus, OrderType } from "../enums/Index";
import { Model } from "./Model";
import { OrderItem } from "./OrderItem";
import { OrderNote } from "./OrderNote";

export interface Order extends Model {
    customerName: string;
    customerPhoneNumber: string;
    deliveryAddressLine1: string;
    deliveryAddressLine2: string;
    deliveryTown: string;
    deliveryPostcode: string;
    orderStatus: OrderStatus;
    orderType: OrderType;
    itemTotal: number;
    discountCodeCode: string;
    discountTotal: number;
    deliveryTotal: number;
    serviceCharge: number;
    grandTotal: number;
    orderPaymentMethod: OrderPaymentMethod;
    checkoutDate: string;
    canCheckout: boolean;
    checkoutProblem: string;
    orderNotes: OrderNote[]
    orderItems: OrderItem[]
}

export class Order implements Order {

	constructor(data?: Partial<Order>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}