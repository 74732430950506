import { Guid } from "guid-typescript";
import { DeliveryMethod } from "../enums/Index";
import { Model } from "./Model";
import { Order } from "./Order";

export interface OrderItem extends Model {
		name: string;
		itemValue: number;
		picture: string;
		quantity: number;
		totalValue: number;
		requiredChoice: string;
		optionalExtras: string;
}

export class OrderItem implements OrderItem {
	constructor(data?: Partial<OrderItem>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}