import { Guid } from "guid-typescript";
import { FetchInterceptor } from "../helpers/Index";


const endpoint = "File";
export const FileService = {
	endpoint,
	download: async (lookupId: Guid) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Download/${lookupId}`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	upload: async (data: FormData) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Upload`, {body: data, method: "POST"}),
	uploadBase64: async (base64: string, containerName: string) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/UploadBase64/${containerName}`, {body: JSON.stringify({image: base64}), headers: {"Content-Type": "application/json"}, method: "POST"}),
	view: async (lookupId: Guid) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/View/${lookupId}`, {headers: {"Content-Type": "application/json"}, method: "GET"})
}