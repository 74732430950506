import { DiscountType } from "../enums/Index";
import { Model } from "./Model";

export interface DeliveryPriceBand extends Model {
	minimumItemValue: number;
	deliveryPrice: number;
	isActive: boolean;
}

export class DeliveryPriceBand implements DeliveryPriceBand {
	constructor(data?: Partial<DeliveryPriceBand>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}