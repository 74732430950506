import { Guid } from "guid-typescript";
import { FetchInterceptor } from "../helpers/Index";
import { MenuCategory } from "../interfaces/Index";
import { Service } from "./Service";

const endpoint = "MenuCategory";
export const MenuCategoryService = {
	endpoint,
	active: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Active`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	createSingle: async (data: MenuCategory) => await Service.createSingle(endpoint, data),
	deleteSingleById: async (id: number) => await Service.deleteSingleById(endpoint, id),
	getAll: async () => await Service.getAll(endpoint),
	getSingleById: async (id: number) => await Service.getSingleById(endpoint, id),
	getSingleByLookupId: async (id: Guid) => await Service.getSingleByLookupId(endpoint, id),
	updateSingleById: async (id: number, data: MenuCategory) => await Service.updateSingleById(endpoint, id, data)
}