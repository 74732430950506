import { Guid } from "guid-typescript";
import { FetchInterceptor } from "../helpers/Index";
import { MenuItem } from "../interfaces/Index";


import { Service } from "./Service";

const endpoint = "MenuItem";
export const DishService = {
	endpoint,
	byChefProfile: async (chefProfileId: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/ByChef/${chefProfileId}`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	byChefProfileLookup: async (chefProfileLookupId: Guid) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/ByChefProfile/${chefProfileLookupId}`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	chefCreate: async (data: MenuItem) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	chefUpdate: async (id: number, data: MenuItem) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/ChefUpdate/${id}`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "PATCH"}),
	createSingle: async (data: MenuItem) => await Service.createSingle(endpoint, data),
	deleteSingleById: async (id: number) => await Service.deleteSingleById(endpoint, id),
	getAll: async () => await Service.getAll(endpoint),
	getDishesNearby: async (postcode: string) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Active`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	getSingleById: async (id: number) => await Service.getSingleById(endpoint, id),
	getSingleByLookupId: async (id: Guid) => await Service.getSingleByLookupId(endpoint, id),
	myArchivedDishes: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/MyArchivedDishes`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	myDishes: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/MyDishes`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	threeMonth: async (dishId: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/ThreeMonth/${dishId}`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	topPicks: async (postcode: string) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Active`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	updateSingleById: async (id: number, data: MenuItem) => await Service.updateSingleById(endpoint, id, data)
}