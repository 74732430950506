import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { UserSignupMethod } from "src/app/enums/Index";
import { ChangePassword, StaffMember } from "src/app/interfaces/Index";
import { FileService, StaffMemberService } from "src/app/services/Index";
import { colours } from "src/app/Styles";
import "./MyAccount.css";
import { InputSwitch } from "primereact/inputswitch";

type Props = {};
type State = {
	changePassword: ChangePassword;
	isChangePasswordLoading: boolean;
	isLoading: boolean;
	showChangePasswordModal: boolean;
	userOriginal: StaffMember;
	user: StaffMember;
	validationErrors: {[key: string]: string[]};
};
export class MyAccount extends React.Component<Props, State> {
	toast: any;

	constructor(props: Props) {
		super(props);
		this.state = {changePassword: new ChangePassword(), isChangePasswordLoading: false, isLoading: false, showChangePasswordModal: false, userOriginal: new StaffMember(), user: new StaffMember(), validationErrors: {} as any};
	}

	changePasswordFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => this.setState({changePassword: new ChangePassword(), showChangePasswordModal: false})}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Save" onClick={() => this.submitPasswordChange()}></Button>
			</>
		);
	}

	async componentDidMount() {
		await this.getUser();
	}

	async getUser() {
		this.setState({isLoading: true});
		const res = await (await StaffMemberService.me()).json();
		if (res !== null) this.setState({user: new StaffMember(res), userOriginal: new StaffMember(res)});
		this.setState({isLoading: false});
	}

	inputChange(e: any, label: string) {
		const val = e.value !== undefined ? e.value : (e.target !== undefined && e.target.value !== undefined) ? e.target.value : "";
		let user = { ...this.state.user };
		// @ts-ignore // Required to be able to use a generic function to change the values
		user[label] = val;
		this.setState({ user });
	}

	render() {
		return (
			<>
				<div className="card p-fluid" style={{ paddingBottom: "0.5rem", paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "1rem" }}>
					<h5>My Account</h5>
					<Row>
						<Col>
							<div className="field" style={{ marginBottom: 10 }}>
								<label htmlFor="email">Email Address</label>
								<InputText aria-describedby="email-help" disabled id="email" required style={{color: colours.black, width: "100%"}} value={this.state.user.emailAddress || ""}/>
								<label id="email-help" style={{color: colours.quaternary, fontSize: 12}}>If you'd like to change your email address, get in touch with our customer support team.</label>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="field">
								<label htmlFor="first-name">Name *</label>
								<InputText disabled={this.state.isLoading} id="first-name" onChange={(e) => this.setState({user: {...this.state.user, name: e.target.value}})} required style={{color: colours.black, width: "100%"}} value={this.state.user.name || ""}/>
							</div>
						</Col>
						<Col>
							<div className="field">
								<label htmlFor="lastLoginDate">Last Login</label>
								<InputText disabled id="lastLoginDate" required style={{color: colours.black, width: "100%"}} value={new Date(this.state.user.lastLoginDate!).toLocaleDateString("en-GB")}/>
							</div>
						</Col>
					</Row>
					<Row><br/>	<div className="field col">
										<label htmlFor="permissions" style={{fontWeight: "bold"}}>Permissions</label></div></Row>
					<Row>
								<Col>
									<div className="field col">
										<label htmlFor="canManageOrders">Manage Orders</label>
										<InputSwitch checked={this.state.user.canManageOrders} readOnly disabled id="canManageOrders" onChange={(e) => this.inputChange(e, "canManageOrders")} />
									</div>
								</Col>
								<Col>
									<div className="field col">
										<label htmlFor="canManageMenu">Manage Menu</label>
										<InputSwitch checked={this.state.user.canManageMenu} readOnly disabled id="canManageMenu" onChange={(e) => this.inputChange(e, "canManageMenu")} />
									</div>
								</Col>
								<Col>
									<div className="field col">
										<label htmlFor="canManageCustomers">Manage Customers</label>
										<InputSwitch checked={this.state.user.canManageCustomers} readOnly disabled id="canManageCustomers" onChange={(e) => this.inputChange(e, "canManageCustomers")} />
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="field col">
										<label htmlFor="canManageRestaurant">Manage Restaurant</label>
										<InputSwitch checked={this.state.user.canManageRestaurant} readOnly disabled id="canManageRestaurant" onChange={(e) => this.inputChange(e, "canManageRestaurant")} />
									</div>
								</Col>
								<Col>
									<div className="field col">
										<label htmlFor="canManageStaff">Manage Staff</label>
										<InputSwitch checked={this.state.user.canManageStaff} readOnly disabled id="canManageStaff" onChange={(e) => this.inputChange(e, "canManageStaff")} />
									</div>
								</Col>
								<Col></Col>
							</Row>
					<Row style={{marginTop:"20px"}}>
						<Col>
							<div className="field" style={{ marginBottom: 10, textAlign: "left" }}>
								<Button iconPos="right" label="Change Password" style={{ backgroundColor: colours.info, borderColor: colours.info, marginRight: 10, width: 200 }} onClick={() =>{this.setState({showChangePasswordModal:true})}}></Button>
							</div>
						</Col>
						<Col>
							<div className="field" style={{ marginBottom: 10, textAlign: "right" }}>
								<Button disabled={JSON.stringify(this.state.user) === JSON.stringify(this.state.userOriginal) || this.state.isLoading === true} iconPos="right" label="Discard" style={{ backgroundColor: colours.buttons.discard, borderColor: colours.buttons.discard, marginRight: 10, width: 150 }} onClick={() => this.setState({ user: this.state.userOriginal })}></Button>
								<Button disabled={JSON.stringify(this.state.user) === JSON.stringify(this.state.userOriginal)} iconPos="right" label="Save" loading={this.state.isLoading} style={{ backgroundColor: colours.buttons.save, borderColor: colours.buttons.save, width: 150 }} onClick={() => this.saveChanges()}></Button>
							</div>
						</Col>
					</Row>
				</div>
				<Dialog className="p-fluid chef-change-password" draggable={false} footer={() => this.changePasswordFooter()} header="Change Password" modal onHide={() => this.setState({changePassword: new ChangePassword(), showChangePasswordModal: false})} style={{minWidth: 450, width: "35%"}} visible={this.state.showChangePasswordModal}>
					<div className="chef-change-password-body">
						<div className="field">
							<label htmlFor="oldPassword">Old Password</label>
							<InputText id="oldPassword" onChange={(e) => this.setState({changePassword: {...this.state.changePassword, oldPassword: e.target.value}})} required style={{color: colours.black}} type="password" value={this.state.changePassword.oldPassword || ""}/>
							{this.state.validationErrors.oldPassword &&
								<small className="p-error" id="oldPassword-help" style={{display: "block", fontSize: 12}}>{this.state.validationErrors.oldPassword.join(" ")}</small>
							}
						</div>
						<div className="field">
							<label htmlFor="newPassword">New Password</label>
							<Password id="newPassword" onChange={(e) => this.setState({changePassword: {...this.state.changePassword, newPassword: e.target.value}})} required style={{color: colours.black}} type="password" value={this.state.changePassword.newPassword || ""}/>
							{this.state.validationErrors.newPassword &&
								<small className="p-error" id="newPassword-help" style={{display: "block", fontSize: 12}}>{this.state.validationErrors.newPassword.join(" ")}</small>
							}
						</div>
						<div className="field">
							<label htmlFor="confirmNewPassword">Confirm New Password</label>
							<Password id="confirmNewPassword" onChange={(e) => this.setState({changePassword: {...this.state.changePassword, confirmNewPassword: e.target.value}})} required style={{color: colours.black}} type="password" value={this.state.changePassword.confirmNewPassword || ""}/>
							{this.state.validationErrors.confirmNewPassword &&
								<small className="p-error" id="confirmNewPassword-help" style={{display: "block", fontSize: 12}}>{this.state.validationErrors.confirmNewPassword.join(" ")}</small>
							}
						</div>
						{this.state.isChangePasswordLoading === true &&
							<div style={{backgroundColor: colours.transparent, bottom: 0, height: "100%", left: 0, position: "absolute", right: 0, top: 0, width: "100%"}}>
								<ProgressSpinner strokeWidth="5" style={{height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", strokeColor: colours.primary, top: "50%", transform: "translate(-50%, -50%)"}}/>
							</div>
						}
					</div>
				</Dialog>
				<Toast ref={(el) => this.toast = el}></Toast>
			</>
		);
	}

	async saveChanges() {
		this.setState({isLoading: true});
		const res = await (await StaffMemberService.updateSingleById(this.state.user.id, this.state.user)).json();
		if (res && res.error) {
			this.toast.show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
			this.setState({isLoading: false});
		} else {
			this.toast.show({closable: false, detail: "Changes saved successfully", life: 5000, severity: "success", summary: "Success"});
			this.setState({isLoading: false, user: res});
			this.getUser();
		}
	}

	async submitPasswordChange() {
		this.setState({isChangePasswordLoading: true, validationErrors: {} as any});
		const res = await (await StaffMemberService.updatePassword(this.state.changePassword)).json();
		if (res && res.validationErrors) this.setState({validationErrors: res.validationErrors});
		else if (res && res.error) this.toast.show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
		else this.toast.show({closable: false, detail: "Changes saved successfully", life: 5000, severity: "success", summary: "Success"});
		this.setState({isChangePasswordLoading: false});
	}

	async uploadImage(data: any, property: string) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", data.files[0]);
		uploadData.append("mimeTypeKey", "Images");
		const response = await (await FileService.upload(uploadData)).json();
		let tempUser = {...this.state.user};
		// @ts-ignore // Required to be able to change the values
		tempUser[property] = `${process.env.REACT_APP_SERVER_STORAGEURL}/${containerName}/${response[0].location}`;
		await this.setState({user: tempUser});
	}
}