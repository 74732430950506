export const colours = {
	black: "#000000",
	brands: {
		facebook: "#4267B2",
		google: "#4284F4"
	},
	buttons: {
		add:"#2BAC65",
		archive: "#f27216",
		block: "#E61818",
		cancel: "#E61818",
		complete:"#2BAC65",
		delete: "#E61818",
		discard: "#E61818",
		edit:"#2BAC65",
		export: "#9C27B0",
		nextStep:"#2BAC65",
		refresh: "#9C27B0",
		save:"#2BAC65",
		unarchive: "#FFD800",
		unblock: "#FFD800",
		view: "#2196f3"
	},
	error: "#DD2222",
	graphs: {
		info: "#FFD800",
		negative: "#E61818",
		negativeBg: "#F7B9B9",
		positive: "#2BAC65",
		primary: "#2196f3",
		primaryBg: "#CAE6FC",
		quaternary: "#9C27B0",
		quaternaryBg: "#E7CBEC",
		secondary: "#F57C00",
		secondaryBg: "#FDE0C2",
		tertiary: "#00BCD4",
		tertiaryBg: "#C2EFF5",
		utility: "#9C27B0"
	},
	info: "#FFBB5B",
	nonary: "#F0AD4E",
	octonary: "#B5B5B5",
	primary: "#b03232",
	quaternary: "#848484",
	quinary: "#E5E5E5",
	secondary: "#e08484",
	senary: "#F7F7F7",
	septenary: "#737373",
	tertiary: "#FF5A5A",
	transparent: "rgba(0, 0, 0, 0.5)",
	white: "#FFFFFF"
};