import { MessageSendingStatus } from "../enums/Index";
import { Model } from "./Model";

export interface DeviceNotification extends Model {
	body?: string;
	deviceNotificationStatus: MessageSendingStatus;
	failureMessage?: string;
	isRead: boolean;
	recipientId: number;
	/**
	 * Convert to a new Date object before use
	 */
	sendAfter?: string;
	/**
	 * Convert to a new Date object before use
	 */
	sentDate?: string;
	title?: string;
	userId?: number;
}

export class DeviceNotification implements DeviceNotification {
	deviceNotificationStatus = MessageSendingStatus.Processing;
	isRead = false;
	recipientId = -1;

	constructor(data?: Partial<DeviceNotification>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}