import { Guid } from "guid-typescript";

export interface TreeNodeItem {
	key: string,
    label: string,
    data: string,
    icon: string,
    children: TreeNodeItem[],
}

export class TreeNodeItem implements TreeNodeItem {

	constructor(data?: Partial<TreeNodeItem>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}