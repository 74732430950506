import { faArrowDown, faArrowUp, faCartShopping, faMoneyBillTrendUp, faPeopleGroup, faStar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormatNumber } from "src/app/helpers/Index";
import { DashboardTile } from "src/app/interfaces/Index";
import { colours } from "src/app/Styles";
import "./Dashboard.css";
import { Chart } from 'primereact/chart';
import { ChartData } from "src/app/interfaces/ChartData";
import { ChartDataset } from "src/app/interfaces/ChartDataset";

type Props = {};
type State = {
	// activity: ActivityItem[];
	chartData: ChartData;
	chartData2: any;
	chartOptions: any;
	customersTile: DashboardTile;
	// dishBreakdown: BreakdownItem[];
	// dishBreakdownTotal: number;
	// goToMyKitchen: boolean;
	// notifications: any;
	// orderDetailsLoading: boolean;
	// orderItem: OrderItem;
	ordersTile: DashboardTile;
	// pendingOrders: any[];
	ratingTile: DashboardTile;
	revenueTile: DashboardTile;
	// salesOverview: any[];
	// selectedOrder: Order;
	// showOrderInfoModal: boolean;
};
export class Dashboard extends React.Component<Props, State> {
	toast: any;

	constructor(props: Props) {
		super(props);
		// this.state = {activity: [], customersTile: new DashboardTile(), dishBreakdown: [], dishBreakdownTotal: 0, goToMyKitchen: false, notifications: [], orderDetailsLoading: false, orderItem: new OrderItem(), ordersTile: new DashboardTile(), pendingOrders: [], ratingTile: new DashboardTile(), revenueTile: new DashboardTile(), salesOverview: [], selectedOrder: new Order(), showOrderInfoModal: false};
		this.state = { chartData: new ChartData, chartData2: null, chartOptions: null, customersTile: new DashboardTile(), ordersTile: new DashboardTile(), ratingTile: new DashboardTile(), revenueTile: new DashboardTile() };
	}

	async componentDidMount() {
		await this.getChart();
		await this.getCustomersTile();
		// 	await this.getDishBreakdown();
		// 	await this.getActivity();
		await this.getOrdersTile();
		// 	await this.getPendingOrders();
		await this.getRatingTile();
		await this.getRevenueTile();
	}

	// async getActivity() {
	// 	const res = await (await OrderService.chefActivity()).json();
	// 	if (res && res.error) this.toast.show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
	// 	else this.setState({activity: res}, () => {
	// 		const currentDate = new Date().toISOString().substring(0, 9);
	// 		const todays = this.state.activity.filter((item) => item.activityOccured.substring(0,9) === currentDate);
	// 		const yesterday = this.state.activity.filter((item) => item.activityOccured.substring(0,9) !== currentDate);

	// 		const data = [
	// 			{day: "Today", notifications: todays},
	// 			{day: "Yesterday", notifications: yesterday}
	// 		];

	// 		this.setState({notifications: data});
	// 	});
	// }

	// async getChefProfile() {
	// 	const res = await (await ChefProfileService.myProfile()).json();
	// 	if (res.status !== ChefStatus.Active && res.status !== ChefStatus.Reported) {
	// 		this.setState({goToMyKitchen: true});
	// 		return false;
	// 	}
	// 	return true;
	// }

	formatDate(date: Date){
		const days = ['Sat','Sun', 'Mon', 'Tue', 'Wed', 'Thu',
				'Fri'] ;
		  var day = date.getDay();
			return days[day];
	 }
	
	
	
	Last7Days () {
			var dateNew = new Date();
			dateNew.setDate(dateNew.getDate())
	console.log(dateNew.getDate());
		var result = [];
		for (var i=0; i<7; i++) {
			var d = new Date();
			d.setDate(d.getDate() - i);
			result.push( this.formatDate(d) )
		}
		return(result.reverse());
	 }

	async getChart() {
		var tempData = new ChartDataset();
		tempData.label = "Sales";
		tempData.data = [65, 10, 53, 21, 45, 85, 75];
		tempData.fill = true;
		tempData.tension = 0.4;
		tempData.borderColor = "#FF0000";
		var tempChart = new ChartData;
		tempChart.labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
		tempChart.dataset = [tempData];

		const data = {
			labels: this.Last7Days(),
			datasets: [
				{
					label: 'Sales',
					data: [65, 59, 80, 81, 56, 55, 40],
					fill: true,
					tension: 0.3,
					borderColor: colours.graphs.negative,
					backgroundColor: colours.graphs.negativeBg,
				}
			]
		};

		const options = {
			maintainAspectRatio: false,
			aspectRatio: 0.6,
			plugins: {
				legend: {
					labels: {
						color: colours.graphs.negative
					}
				}
			},
			scales: {
				x: {
					ticks: {
						color: colours.graphs.negative
					},
					grid: {
						color: colours.graphs.negativeBg
					}
				},
				y: {
					ticks: {
						color: colours.graphs.negative
					},
					grid: {
						color: colours.graphs.negativeBg
					}
				}
			}
		};

		this.setState({ chartData: tempChart, chartData2: data, chartOptions: options });
	}

	async getCustomersTile() {
		// const res = await (await OrderService.chefCustomersTile()).json();
		// if (res && res.error) this.toast.show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
		// else this.setState({customersTile: res});
		var tempTile = new DashboardTile();
		tempTile.amount = 12;
		tempTile.change = 10
		this.setState({ customersTile: tempTile });
	}

	// async getDishBreakdown() {
	// 	const res = await (await OrderService.dishBreakdown()).json();
	// 	if (res && res.error) this.toast.show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
	// 	else {
	// 		let total = 0;
	// 		res.forEach((dish: BreakdownItem) => total += dish.count);
	// 		res.sort((a: BreakdownItem, b: BreakdownItem) => {
	// 			if (a.count > b.count) return -1;
	// 			else if (a.count < b.count) return 1;
	// 			else return 0;
	// 		});
	// 		this.setState({dishBreakdown: res, dishBreakdownTotal: total});
	// 	}
	// }

	// getNumericColor(value: number) {
	// 	switch (value) {
	// 		case 0:
	// 			return colours.graphs.primary;
	// 		case 1:
	// 			return colours.graphs.secondary;
	// 		case 2:
	// 			return colours.graphs.tertiary;
	// 		case 3:
	// 			return colours.graphs.quaternary;
	// 		default:
	// 			return colours.graphs.primary;
	// 	}
	// }

	async getOrdersTile() {
		var tempTile = new DashboardTile();
		tempTile.amount = 29;
		tempTile.change = 5;
		this.setState({ ordersTile: tempTile });
	}

	// async getPendingOrders() {
	// 	const res = await (await OrderService.chefPendingOrders()).json();
	// 	if (res && res.error) this.toast.show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
	// 	else this.setState({pendingOrders: res}, () => this.state.selectedOrder.id && this.state.selectedOrder.id > -1 ? this.setState({selectedOrder: this.state.pendingOrders.find((order) => order.id === this.state.selectedOrder.id)!}) : null);
	// }

	async getRatingTile() {
		var tempTile = new DashboardTile();
		tempTile.amount = 4.8;
		tempTile.change = 0.2;
		this.setState({ ratingTile: tempTile });
	}

	async getRevenueTile() {
		var tempTile = new DashboardTile();
		tempTile.amount = 954.95;
		tempTile.change = 342.98;
		this.setState({ revenueTile: tempTile });
	}

	// async orderApprove() {
	// 	this.setState({orderDetailsLoading: true});
	// 	const res = await (await OrderService.orderApprove(this.state.selectedOrder.id, this.state.selectedOrder)).json();
	// 	if (res && res.error) this.toast.show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
	// 	else {
	// 		this.toast.show({closable: false, detail: "You have accepted the order, the customer will be notified.", life: 5000, severity: "success", summary: "Order accepted!"});
	// 		this.getPendingOrders();
	// 	}
	// 	this.setState({orderDetailsLoading: false});
	// }

	// async orderComplete() {
	// 	this.setState({orderDetailsLoading: true});
	// 	const res = await (await OrderService.orderComplete(this.state.selectedOrder.id, this.state.selectedOrder)).json();
	// 	if (res && res.error) this.toast.show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
	// 	else {
	// 		this.toast.show({closable: false, detail: "Order collected, the customer will be notified.", life: 5000, severity: "success", summary: "Order collected!"});
	// 		this.setState({selectedOrder: new Order(), showOrderInfoModal: false}, () => this.getPendingOrders());
	// 	}
	// 	this.setState({orderDetailsLoading: false});
	// }

	// async orderReady() {
	// 	this.setState({orderDetailsLoading: true});
	// 	const res = await (await OrderService.orderReady(this.state.selectedOrder.id, this.state.selectedOrder)).json();
	// 	if (res && res.error) this.toast.show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
	// 	else {
	// 		this.toast.show({closable: false, detail: "Order ready, the customer/courier will be notified.", life: 5000, severity: "success", summary: "Order Ready!"});
	// 		this.getPendingOrders();
	// 	}
	// 	this.setState({orderDetailsLoading: false});
	// }

	// async orderReject() {
	// 	this.setState({orderDetailsLoading: true});
	// 	const res = await (await OrderService.orderReject(this.state.selectedOrder.id, this.state.selectedOrder)).json();
	// 	if (res && res.error) this.toast.show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
	// 	else {
	// 		this.toast.show({closable: false, detail: "You have rejected the order, the customer will be notified.", life: 5000, severity: "success", summary: "Order rejected!"});
	// 		this.setState({selectedOrder: new Order(), showOrderInfoModal: false}, () => this.getPendingOrders());
	// 	}
	// 	this.setState({orderDetailsLoading: false});
	// }

	render() {

		return (
			<>
				<Row>
					<Col lg={6} xl={3} xs={12}>
						<div className="card" style={{ marginBottom: "1.5rem", padding: "1.5rem" }}>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<span className="mb-3" style={{ color: colours.quaternary, fontWeight: 500 }}>Orders</span>
									<div style={{ fontSize: "1.25rem", fontWeight: 500 }}>{FormatNumber(this.state.ordersTile.amount)}</div>
								</div>
								<div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: colours.graphs.primaryBg, height: "2.5rem", width: "2.5rem" }}>
									<FontAwesomeIcon icon={faCartShopping} style={{ color: colours.graphs.primary, fontSize: "1.25rem" }} />
								</div>
							</div>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<span style={{ color: this.state.ordersTile.change > 0 ? colours.graphs.positive : this.state.customersTile.change < 0 ? colours.graphs.negative : colours.quaternary, fontWeight: 500, paddingRight: 5 }}>
									{this.state.ordersTile.change > 0 &&
										<FontAwesomeIcon icon={faArrowUp} style={{ marginRight: 3 }} />
									}
									{this.state.ordersTile.change < 0 &&
										<FontAwesomeIcon icon={faArrowDown} style={{ marginRight: 3 }} />
									}
									{FormatNumber(this.state.ordersTile.change)}
								</span>
								<span style={{ color: colours.quaternary }}>since last month</span>
							</div>
						</div>
					</Col>
					<Col lg={6} xl={3} xs={12}>
						<div className="card" style={{ marginBottom: "1.5rem", padding: "1.5rem" }}>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<span className="mb-3" style={{ color: colours.quaternary, fontWeight: 500 }}>Revenue</span>
									<div style={{ fontSize: "1.25rem", fontWeight: 500 }}>{FormatNumber(this.state.revenueTile.amount, true)}</div>
								</div>
								<div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: colours.graphs.secondaryBg, height: "2.5rem", width: "2.5rem" }}>
									<FontAwesomeIcon icon={faMoneyBillTrendUp} style={{ color: colours.graphs.secondary, fontSize: "1.25rem" }} />
								</div>
							</div>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<span style={{ color: this.state.revenueTile.change > 0 ? colours.graphs.positive : this.state.revenueTile.change < 0 ? colours.graphs.negative : colours.quaternary, fontWeight: 500, paddingRight: 5 }}>
									{this.state.revenueTile.change > 0 &&
										<FontAwesomeIcon icon={faArrowUp} style={{ marginRight: 3 }} />
									}
									{this.state.revenueTile.change < 0 &&
										<FontAwesomeIcon icon={faArrowDown} style={{ marginRight: 3 }} />
									}
									{FormatNumber(this.state.revenueTile.change, true)}
								</span>
								<span style={{ color: colours.quaternary }}>since last week</span>
							</div>
						</div>
					</Col>
					<Col lg={6} xl={3} xs={12}>
						<div className="card" style={{ marginBottom: "1.5rem", padding: "1.5rem" }}>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<span className="mb-3" style={{ color: colours.quaternary, fontWeight: 500 }}>Customers</span>
									<div style={{ fontSize: "1.25rem", fontWeight: 500 }}>{FormatNumber(this.state.customersTile.amount)}</div>
								</div>
								<div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: colours.graphs.tertiaryBg, height: "2.5rem", width: "2.5rem" }}>
									<FontAwesomeIcon icon={faPeopleGroup} style={{ color: colours.graphs.tertiary, fontSize: "1.25rem" }} />
								</div>
							</div>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<span style={{ color: this.state.customersTile.change > 0 ? colours.graphs.positive : this.state.customersTile.change < 0 ? colours.graphs.negative : colours.quaternary, fontWeight: 500, paddingRight: 5 }}>
									{this.state.customersTile.change > 0 &&
										<FontAwesomeIcon icon={faArrowUp} style={{ marginRight: 3 }} />
									}
									{this.state.customersTile.change < 0 &&
										<FontAwesomeIcon icon={faArrowDown} style={{ marginRight: 3 }} />
									}
									{FormatNumber(this.state.customersTile.change)}
								</span>
								<span style={{ color: colours.quaternary }}>newly registered</span>
							</div>
						</div>
					</Col>
					<Col lg={6} xl={3} xs={12}>
						<div className="card" style={{ marginBottom: "1.5rem", padding: "1.5rem" }}>
							<div className="d-flex justify-content-between mb-3">
								<div>
									<span className="mb-3" style={{ color: colours.quaternary, fontWeight: 500 }}>Rating</span>
									<div style={{ fontSize: "1.25rem", fontWeight: 500 }}>{this.state.ratingTile.amount}/5</div>
								</div>
								<div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: colours.graphs.quaternaryBg, height: "2.5rem", width: "2.5rem" }}>
									<FontAwesomeIcon icon={faStar} style={{ color: colours.graphs.quaternary, fontSize: "1.25rem" }} />
								</div>
							</div>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<span style={{ color: this.state.ratingTile.change > 0 ? colours.graphs.positive : this.state.ratingTile.change < 0 ? colours.graphs.negative : colours.quaternary, fontWeight: 500, paddingRight: 5 }}>
									{this.state.ratingTile.change > 0 &&
										<FontAwesomeIcon icon={faArrowUp} style={{ marginRight: 3 }} />
									}
									{this.state.ratingTile.change < 0 &&
										<FontAwesomeIcon icon={faArrowDown} style={{ marginRight: 3 }} />
									}
									{this.state.ratingTile.change}
								</span>
								<span style={{ color: colours.quaternary }}>rating change</span>
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="card">
							<Chart type="line" data={this.state.chartData2} options={this.state.chartOptions} />
						</div>
					</Col>
				</Row>
			</>
		);
	}

	// renderNotifications() {
	// 	return (
	// 		<div className="card" style={{padding: "1.5rem"}}>
	// 			<h5>Activity</h5>
	// 			{this.state.notifications.map((data: any) => {
	// 				if (data.notifications.length != 0) {
	// 					return (
	// 						<div key={`notification-day-${data.day}`} style={{marginTop: 24}}>
	// 							<span style={{color: colours.septenary, fontSize: 15, fontWeight: "bold", marginBottom: 12, textTransform: "uppercase"}}>{data.day}</span>
	// 							{data.notifications.map((notification: any, i: number) => {
	// 								let backgroundColour = "";
	// 								let colour = "";
	// 								let icon;

	// 								switch (notification.activityType) {
	// 									case "ORDER":
	// 										backgroundColour = colours.graphs.primaryBg;
	// 										colour = colours.graphs.primary;
	// 										icon = faSterlingSign;
	// 										break;
	// 									case "CANCEL":
	// 										backgroundColour = colours.graphs.secondaryBg;
	// 										colour = colours.graphs.secondary;
	// 										icon = faMoneyFromBracket;
	// 										break;
	// 									case "REVIEW":
	// 										backgroundColour = colours.graphs.tertiaryBg;
	// 										colour = colours.graphs.tertiary;
	// 										icon = faComment;
	// 										break;
	// 									default:
	// 										backgroundColour = colours.graphs.quaternaryBg;
	// 										colour = colours.graphs.quaternary;
	// 										icon = faQuestion;
	// 										break;
	// 								}
	// 								return (
	// 									<div key={`notification-${data.day}-${i}`} style={{alignItems: "center", borderBottomWidth: i === data.notifications.length - 1 ? 0 : 1, borderColor: colours.quaternary, borderStyle: "solid", borderLeftWidth: 0, borderRightWidth: 0, borderTopWidth: 0, display: "flex", height: 50}}>
	// 										<div className="d-flex align-items-center justify-content-center" style={{backgroundColor: backgroundColour, height: "2.5rem", width: "2.5rem"}}>
	// 											<FontAwesomeIcon icon={icon} style={{color: colour, fontSize: "1.25rem"}}/>
	// 										</div>
	// 										<span style={{color: colours.septenary, fontSize: 15, marginLeft: 8}}>{notification.description}</span>
	// 									</div>
	// 								);
	// 							})}
	// 						</div>
	// 					);
	// 				}
	// 			})}
	// 		</div>
	// 	);
	// }
}