import { Model } from "./Model";

export interface StaffMember extends Model {
    name?: string;
    emailAddress?: string;
    passwordExpiryDate?: string;
    lastLoginDate?: string;
    refreshToken?: string;
    refreshTokenExpiryDate?: string;
    password: string;
    confirmPassword: string;
    passwordResetToken?: string;
    passwordResetTokenExpiryDate?: string;
    isEnabled: boolean;
    canManageStaff: boolean;
    canManageMenu: boolean;
    canManageRestaurant: boolean;
    canManageOrders: boolean;
    canManageCustomers: boolean;
}

export class StaffMember implements StaffMember {
	constructor(data?: Partial<StaffMember>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}