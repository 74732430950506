import { faCircleDot } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRightFromBracket, faBadgePercent, faBoxArchive, faCrosshairs, faFireBurner, faHouseBlank, faPeople, faPlateUtensils, faRectangleHistory, faStar, faUser, faUserChef } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React from "react";
import { Link, Navigate, NavLink, Outlet, useNavigate } from "react-router-dom";
import { RestaurantSettings, StaffMember, User } from "src/app/interfaces/Index";
import { AppSettingsService, RestaurantSettingsService, StaffMemberService, UserService } from "src/app/services/Index";
import { colours } from "src/app/Styles";
import "./Main.css";

type Props = {};
type State = {
	canCloseFirstTimeLoginModal: boolean;
	chefProfile?: StaffMember;
	isLoading: boolean;
	isLoggedIn: boolean;
	isWelcomeDataLoading: boolean;
	mobileTopbarMenuClick: boolean;
	menuItems: any[];
	redirectToMyKitchen: boolean;
	showFirstTimeLoginModal: boolean;
	showForm: boolean;
	user?: StaffMember;
	validationErrors: { [key: string]: string[] };
};
export class Main extends React.Component<Props, State> {
	toast: any;


	constructor(props: Props) {
		super(props);
		this.state = { canCloseFirstTimeLoginModal: false, isLoading: false, isLoggedIn: true, isWelcomeDataLoading: false, mobileTopbarMenuClick: false, menuItems: [], redirectToMyKitchen: false, showForm: false, showFirstTimeLoginModal: false, validationErrors: {} as any };
	}

	componentDidMount() {
		this.isUserLoggedIn();
	}

	async getChefProfile() {
		const res = await (await StaffMemberService.me()).json();
		if (res === null) this.setState({ isLoggedIn: false });
		else {
			this.setState({ isLoggedIn: true, chefProfile: new StaffMember(res) });

		}
	}

	getMenuItems(user: StaffMember) {
		let menuItems = [];
		let menuItemsSub = [];

		console.log(this.state.user);

		if (user.canManageOrders) {

			menuItemsSub.push({ icon: faHouseBlank, label: "Dashboard", template: (item: any) => this.renderMenuItem(item), to: `/admin` })
			menuItemsSub.push({ icon: faFireBurner, label: "Orders", template: (item: any) => this.renderMenuItem(item), to: `/admin/orders` })
		}

		if (user.canManageMenu) {
			menuItemsSub.push({ icon: faPlateUtensils, label: "Menu", template: (item: any) => this.renderMenuItem(item), to: `/admin/menu` })
		}

		if (user.canManageRestaurant) {
			menuItemsSub.push({ icon: faBadgePercent, label: "Coupons", template: (item: any) => this.renderMenuItem(item), to: `/admin/coupons` })
			menuItemsSub.push({ icon: faCrosshairs, label: "Delivery", template: (item: any) => this.renderMenuItem(item), to: `/admin/delivery` })
			menuItemsSub.push({ icon: faUserChef, label: "Restaurant", template: (item: any) => this.renderMenuItem(item), to: `/admin/restaurant` })
		}

		if (user.canManageStaff) {
			menuItemsSub.push({ icon: faPeople, label: "Staff", template: (item: any) => this.renderMenuItem(item), to: `/admin/staff` })
		}

		menuItemsSub.push({ icon: faUser, label: "Account", template: (item: any) => this.renderMenuItem(item), to: `/admin/my-account` });
		menuItemsSub.push({ icon: faArrowRightFromBracket, label: "Sign Out", template: (item: any) => this.renderMenuItemSignOut(item), to: `` });

		menuItems.push({
			label: "admin",
			items: menuItemsSub
		});

		this.setState({ menuItems: menuItems });
	}

	async isUserLoggedIn() {
		try {
			const res = await (await StaffMemberService.me()).json();
			if (res !== undefined) {
				this.setState({ isLoggedIn: true, user: new StaffMember(res) });
				this.getMenuItems(res);
			} else {
				this.setState({ isLoggedIn: false });
			}
		} catch (ex) {
			await this.signOut();
		}
	}

	mobileTopbarMenuClick(event: any) {
		this.setState({ mobileTopbarMenuClick: !this.state.mobileTopbarMenuClick });
		event.preventDefault();
	}

	render() {
		//return this.renderMain();
		if (this.state.isLoggedIn === false || this.state.isLoggedIn === undefined) return this.renderLogin();
		if (this.state.user !== undefined) {
			return this.renderMain();
		}
		else return;


	}

	renderLogin() {
		return (
			<Navigate replace={true} to={`/`} />
		);
	}

	renderMain() {
		return (
			<div style={{ backgroundColor: "#EDF1F5", minHeight: "100vh", paddingTop: "4rem" }}>
				{this.renderStatusBar()}
				<div className="layout-topbar">
					<button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={(event) => this.mobileTopbarMenuClick(event)}>
						<i className="pi pi-ellipsis-v" />
					</button>
					<Link className="layout-topbar-logo" style={{ textDecoration: "none" }} to={``}>
						<img src="/assets/Logo_Long.png" style={{ height: 40 }}></img>
					</Link>
				</div>
				<Menu model={this.state.menuItems} />
				<div style={{ marginLeft: 364, marginRight: 32, marginTop: 32, paddingBottom: 32, width: "calc(100% - 396px)" }}>
					<Outlet />
				</div>
			</div>
		);
	}

	renderMenuItem(item: any) {
		return (
			<NavLink className="p-menuitem-link" end style={{ position: "relative" }} to={item.to}>
				<FontAwesomeIcon icon={item.icon} style={{ color: colours.black, fontSize: 15, width: 40 }} />
				<span className="p-menuitem-text">{item.label}</span>
				{item.badge &&
					<Badge style={{ backgroundColor: colours.primary, position: "absolute", right: 10 }} value={item.badge}></Badge>
				}
			</NavLink>
		);
	}

	renderMenuItemSignOut(item: any) {
		return (
			<div className="p-menuitem-link" onClick={() => this.signOut()} style={{ position: "relative" }}>
				<FontAwesomeIcon icon={item.icon} style={{ color: colours.error, fontSize: 15, width: 40 }} />
				<span className="p-menuitem-text" style={{ color: colours.error }}>{item.label}</span>
			</div>
		);
	}

	renderStatusBar() {
		// if (this.state.chefProfile!.status === ChefStatus.InactiveServiceArea) {
		// 	return (
		// 		<div className="warning-bar">
		// 			<div className="warning-bar-text">Your service area is currently inactive and requires further action by admins.</div>
		// 		</div>
		// 	);
		// }
		// else if (this.state.chefProfile!.status === ChefStatus.Pending) {
		// 	return (
		// 		<div className="warning-bar">
		// 			<div className="warning-bar-text">Your account is currently Pending Approval and access is limited.</div>
		// 		</div>
		// 	);
		// }
		// else if (this.state.chefProfile?.isAway) {
		// 	return (
		// 		<div className="warning-bar">
		// 			<div className="warning-bar-text">YOUR STATUS IS SET TO AWAY.</div>
		// 		</div>
		// 	);
		// } else return null;
		return null;
	}


	renderStatusItem(item: any) {
		return (
			<></>
			// <div className="p-menuitem-link" style={{backgroundColor: this.state.chefProfile?.isAway ? colours.nonary : colours.primary, position: "relative"}} onClick={() => this.setAway()}>
			// 	<FontAwesomeIcon icon={item.icon} style={{color: colours.white, fontSize: 15, width: 40}}></FontAwesomeIcon>
			// 	<span className="p-menuitem-text" style={{color: colours.white}}>{this.state.chefProfile?.isAway ? "Away (change)" : "Online (change)"}</span>
			// </div>
		);
	}

	async setAway() {
		const res = await (await RestaurantSettingsService.toggleOnline()).json();
		this.getChefProfile();
	}

	async signOut() {
		await AppSettingsService.remove("accessToken");
		await AppSettingsService.remove("refreshToken");
		this.setState({ isLoggedIn: false });
	}
}