import React from "react";

type Props = {};
type State = {};
export class Unauthorised401 extends React.Component<Props, State> {
	render() {
		return (
			<div style={{textAlign: "center"}}>
				<br/>
				<h1>401 Unauthorised</h1>
				<h2>Uh Oh... You werent supposed to be there!</h2>
				<br/>
				<img src="/assets/401.png" style={{width:"30vw"}}></img>
			</div>
		);
	}
}