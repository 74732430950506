import { faPencil, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Rating } from "primereact/rating";
import { SelectButton } from "primereact/selectbutton";
import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormatBooleanYesNo } from "src/app/helpers/FormatBooleanYesNo";
import { FormatNumber, GetPropertiesFromEnum } from "src/app/helpers/Index";
import { MenuItem, MenuCategory, MenuItemOption, DeliveryPriceBand, DeliveryArea } from "src/app/interfaces/Index";
import { MenuCategoryService, DishService, FileService, ImageService, DeliveryPriceBandService, DeliveryAreaService } from "src/app/services/Index";
import { colours } from "src/app/Styles";
import "./Delivery.css";
import { Calendar } from "primereact/calendar";
import { Navigate } from "react-router-dom";

type Props = {};
type State = {
	areas: DeliveryArea[];
	bands: DeliveryPriceBand[];
	isLoading: boolean;
	selectedArea: DeliveryArea;
	selectedAreas: DeliveryArea[];
	selectedBand: DeliveryPriceBand;
	selectedBands: DeliveryPriceBand[];
	showAreaInfoModal: boolean;
	showDeleteAreaModal: boolean;
	showDeleteAreaSelectionModal: boolean;
	showDeleteModal: boolean;
	showDeleteSelectionModal: boolean;
	showBandInfoModal: boolean;
	submitted: boolean;
};
export class Delivery extends React.Component<Props, State> {
	dataTable: any;
	toast: any;

	constructor(props: Props) {
		super(props);
		this.state = {areas: [], bands: [], isLoading: false, selectedArea: new DeliveryArea(), selectedAreas: [], selectedBand: new DeliveryPriceBand(), selectedBands: [], showAreaInfoModal:false, showDeleteAreaModal:false, showDeleteAreaSelectionModal:false, showDeleteModal: false, showDeleteSelectionModal: false, showBandInfoModal: false, submitted: false};
	}

	categoryChange(e: any) {
		let selectedDish = { ...this.state.selectedBand };
		// selectedDish.type = e.value;
		this.setState({ selectedBand: selectedDish });
	}

	componentDidMount() {
		this.getAreas();
		this.getBands();
	}

	deleteDishFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="No" onClick={() => this.setState({ showDeleteModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Yes" onClick={(data) => this.deleteBandConfirm(data)}></Button>
			</>
		);
	}

	deleteDishesFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="No" onClick={() => this.setState({ showDeleteSelectionModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Yes" onClick={() => this.deleteSelectedDishesConfirm()}></Button>
			</>
		);
	}

	async deleteAreaConfirm(rowData: any) {
		const res = await DeliveryAreaService.deleteSingleById(this.state.selectedArea.id);
		if (res && res.status !== 200) {
			this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
			this.setState({ isLoading: false });
		} else {
			this.setState({ isLoading: false, selectedArea: new DeliveryArea(), showDeleteModal: false })
			this.toast.show({ closable: false, detail: "Category Successfully Deleted", life: 5000, severity: "warn", summary: "Category Deleted" });
			this.getAreas();
		};
	}

	async deleteBandConfirm(rowData: any) {
		const res =(await DeliveryPriceBandService.deleteSingleById(this.state.selectedBand.id));
		console.log(res);
		console.log(res.status);
		if (res && res.status !== 200) {
			this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
			this.setState({ isLoading: false });
		} else {
			this.setState({ isLoading: false, selectedBand: new DeliveryPriceBand(), showDeleteModal: false })
			this.toast.show({ closable: false, detail: "Menu Item Successfully Deleted", life: 5000, severity: "warn", summary: "Menu Item Deleted" });
			this.getBands();
		};
	}

	deleteMenuCategoryFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="No" onClick={() => this.setState({ showDeleteModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Yes" onClick={(data) => this.deleteAreaConfirm(data)}></Button>
			</>
		);
	}

	deleteSelectedDishesConfirm() { }

	dishInfoFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => this.setState({ selectedBand: new DeliveryPriceBand(), showBandInfoModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label={this.state.selectedBand.id === undefined || this.state.selectedBand.id === -1 ? "Create" : "Save"} onClick={() => this.saveBand()}></Button>
			</>
		);
	}

	async getAreas() {
		this.setState({ isLoading: true });
		const res = await (await DeliveryAreaService.getAll()).json();
		if (res && res.error) {
			this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
			this.setState({ isLoading: false });
		} else this.setState({ areas: res, isLoading: false });
	}

	async getBands() {
		this.setState({ isLoading: true });
		const res = await (await DeliveryPriceBandService.getAll()).json();
		if (res && res.error) {
			this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
			this.setState({ isLoading: false });
		} else this.setState({ bands: res, isLoading: false });
	}

	inputCategoryChange(e: any, name: string) {
		const val = e.value !== undefined ? e.value : (e.target !== undefined && e.target.value !== undefined) ? e.target.value : "";

		if (name === "offerDescription" && val.length > 10) {
			e.preventDefault();
			return false;
		}

		let selectedCategory = { ...this.state.selectedArea };
		// @ts-ignore // Required to be able to use a generic function to change the values
		selectedCategory[name] = val;
		this.setState({ selectedArea: selectedCategory });
	}

	inputChange(e: any, name: string) {
		const val = e.value !== undefined ? e.value : (e.target !== undefined && e.target.value !== undefined) ? e.target.value : "";

		if (name === "offerDescription" && val.length > 10) {
			e.preventDefault();
			return false;
		}

		let selectedDish = { ...this.state.selectedBand };
		// @ts-ignore // Required to be able to use a generic function to change the values
		selectedDish[name] = val;
		this.setState({ selectedBand: selectedDish });
	}

	menuCategoryInfoFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => this.setState({ selectedArea: new DeliveryArea(), showAreaInfoModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label={this.state.selectedArea.id === undefined || this.state.selectedArea.id === -1 ? "Create" : "Save"} onClick={() => this.saveArea()}></Button>
			</>
		);
	}

	onRequiredEdit(e: any) {
	}

	render() {
		return (
			<>
				<Row>
					<Col>
						<div className="card">
							<h5>Delivery Settings</h5>
							<TabView className="tabview-header-icon">
							<TabPanel header="Areas" leftIcon="pi pi-book">
							<Toolbar left={() => this.renderLeftToolbarButtonsCategory()} right={() => this.renderRightToolbarButtonsCategory()}></Toolbar>
							<DataTable stripedRows loading={this.state.isLoading} filterDisplay="row" onSelectionChange={(e) => this.setState({ selectedAreas: e.value })} ref={(el) => this.dataTable = el} scrollable scrollHeight="flex" selection={this.state.selectedAreas} style={{ height: "calc(100vh - 17.5rem)" }} value={this.state.areas}>
								<Column field="outcode" header="Outcode" filter sortable></Column>
								<Column header="Is Active" body={(rowData: any) => this.renderVisibleStatusBadge(rowData)} sortable></Column>
								<Column style={{ width: 75 }} body={(rowData) => (
									<div className="actions">
										<Button onClick={() => this.renderCategoryDialog(rowData)} style={{ backgroundColor: colours.buttons.edit, borderColor: colours.buttons.edit, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
											<FontAwesomeIcon icon={faPencil} style={{ color: colours.white }} />
										</Button>
										<Button onClick={() => this.setState({ selectedArea: rowData, showDeleteModal: true })} style={{ backgroundColor: colours.buttons.delete, borderColor: colours.buttons.delete, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
											<FontAwesomeIcon icon={faTrashAlt} style={{ color: colours.black }} />
										</Button>
									</div>
								)}></Column>
							</DataTable>
								</TabPanel>
						<TabPanel header="Price Bands" leftIcon="pi pi-box">
							<Toolbar left={() => this.renderLeftToolbarButtons()} right={() => this.renderRightToolbarButtons()}></Toolbar>
							<DataTable stripedRows loading={this.state.isLoading} onSelectionChange={(e) => this.setState({ selectedBands: e.value })} ref={(el) => this.dataTable = el} scrollable scrollHeight="flex" selection={this.state.selectedBands} style={{ height: "calc(100vh - 17.5rem)" }} value={this.state.bands}>
								<Column field="minimumItemValue" header="Minimum Item Value" sortable></Column>
								<Column field="deliveryPrice" header="Delivery Price" sortable></Column>
								<Column header="Is Active" body={(rowData: any) => this.renderVisibleStatusBadge(rowData)} sortable></Column>
								<Column style={{ width: 75 }} body={(rowData) => (
									<div className="actions">
										<Button onClick={() => this.renderDialog(rowData)} style={{ backgroundColor: colours.buttons.edit, borderColor: colours.buttons.edit, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
											<FontAwesomeIcon icon={faPencil} style={{ color: colours.white }} />
										</Button>
										<Button onClick={() => this.setState({ selectedBand: rowData, showDeleteModal: true })} style={{ backgroundColor: colours.buttons.delete, borderColor: colours.buttons.delete, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
											<FontAwesomeIcon icon={faTrashAlt} style={{ color: colours.black }} />
										</Button>
									</div>
								)}></Column>
							</DataTable>
							</TabPanel>
							</TabView>
							<Dialog className="p-fluid" footer={() => this.menuCategoryInfoFooter()} header="Area Details" modal onHide={() => this.setState({ showAreaInfoModal: false })} style={{ width: "75%", height: "85vh" }} visible={this.state.showAreaInfoModal}>
										<Row>
											<Col>
											<div className="field">
												<label htmlFor="outcode">Outcode</label>
												<InputText className={classNames({ "p-invalid": this.state.submitted && !this.state.selectedArea.outcode })} id="outcode" onChange={(e) => this.inputCategoryChange(e, "outcode")} readOnly={false} disabled={false} required value={this.state.selectedArea.outcode} />
												{this.state.submitted && !this.state.selectedArea.outcode &&
													<small className="p-invalid">Outcode is required.</small>
												}
											</div>
											</Col>
											<Col>
												<div className="field">
													<label htmlFor="isActive" className="switchSpacing">Is Active</label>
													<InputSwitch id="isActive" onChange={(e) => this.inputCategoryChange(e, "isActive")} required checked={this.state.selectedArea.isActive} />
												</div>
											</Col>
										</Row>
							</Dialog>
							<Dialog footer={() => this.deleteMenuCategoryFooter()} header="Confirm" modal onHide={() => this.setState({ showDeleteModal: false })} style={{ width: 550 }} visible={this.state.showDeleteModal}>
								<div className="d-flex align-items-center justify-content-center">
									<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem", marginRight: "0.5rem" }} />
									{this.state.selectedArea &&
										<span>Are you sure you want to archive <b>{this.state.selectedArea.outcode}</b>?</span>
									}
								</div>
							</Dialog>
							<Dialog className="p-fluid" footer={() => this.dishInfoFooter()} header="Delivery Price Band Detail" modal onHide={() => this.setState({ showBandInfoModal: false })} style={{ width: "75%", height: "85vh" }} visible={this.state.showBandInfoModal}>
								<TabView className="tabview-header-icon">
									<TabPanel header="Basic Information" leftIcon="pi pi-info-circle">
										<Row>
											<Col>
											<div className="field">
												<label htmlFor="deliveryPrice">Delivery Price</label>
												<InputText autoFocus className={classNames({ "p-invalid": this.state.submitted && !this.state.selectedBand.deliveryPrice })} id="deliveryPrice" onChange={(e) => this.inputChange(e, "deliveryPrice")} required value={this.state.selectedBand.deliveryPrice} />
												{this.state.submitted && !this.state.selectedBand.deliveryPrice &&
													<small className="p-invalid">Delivery Price is required.</small>
												}
											</div>
											</Col>
											<Col>
											<div className="field">
												<label htmlFor="minimumItemValue">Minimum Item Value</label>
												<InputText autoFocus className={classNames({ "p-invalid": this.state.submitted && !this.state.selectedBand.minimumItemValue })} id="minimumItemValue" onChange={(e) => this.inputChange(e, "minimumItemValue")} required value={this.state.selectedBand.minimumItemValue} />
												{this.state.submitted && !this.state.selectedBand.minimumItemValue &&
													<small className="p-invalid">Minimum Item Value is required.</small>
												}
											</div>
											</Col>
										</Row>
										<Row>
											<Col>
												<div className="field">
													<label htmlFor="isActive" className="switchSpacing">Is Active</label>
													<InputSwitch id="isActive" onChange={(e) => this.inputChange(e, "isActive")} required checked={this.state.selectedBand.isActive} />
												</div>
											</Col>
										</Row>
									</TabPanel>
								</TabView>
							</Dialog>
							<Dialog footer={() => this.deleteDishFooter()} header="Confirm" modal onHide={() => this.setState({ showDeleteModal: false })} style={{ width: 550 }} visible={this.state.showDeleteModal}>
								<div className="d-flex align-items-center justify-content-center">
									<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem", marginRight: "0.5rem" }} />
									{this.state.selectedBand &&
										<span>Are you sure you want to archive this band?</span>
									}
								</div>
							</Dialog>
						</div>
					</Col>
				</Row>
				<Toast ref={(el) => this.toast = el}></Toast>	
			</>
		);
	}

	renderCategoryDialog(rowData: any) {
		this.setState({ selectedArea: rowData, showAreaInfoModal: true })
	}

	renderDialog(rowData: any) {
		this.setState({selectedBand: rowData, showBandInfoModal:true});
	}

	renderLeftToolbarButtons() {
		return (
			<>
				<Button icon="pi pi-plus" label="New" style={{ backgroundColor: colours.buttons.add, borderColor: colours.buttons.add, marginRight: 10 }} onClick={() => this.setState({ selectedBand: new DeliveryPriceBand(), showBandInfoModal: true })}></Button>
			</>
		);
	}

	renderLeftToolbarButtonsCategory() {
		return (
			<>
				<Button icon="pi pi-plus" label="New" style={{ backgroundColor: colours.buttons.add, borderColor: colours.buttons.add, marginRight: 10 }} onClick={() => this.setState({ selectedArea: new DeliveryArea(), showAreaInfoModal: true })}></Button>
			</>
		);
	}

	renderRightToolbarButtons() {
		return (
			<>
				<Button icon="pi pi-download" label="Export" style={{ backgroundColor: colours.buttons.export, borderColor: colours.buttons.export, marginLeft: 10 }} onClick={() => this.dataTable.exportCSV()}></Button>
			</>
		);
	}

	renderRightToolbarButtonsCategory() {
		return (
			<>
				<Button icon="pi pi-download" label="Export" style={{ backgroundColor: colours.buttons.export, borderColor: colours.buttons.export, marginLeft: 10 }} onClick={() => this.dataTable.exportCSV()}></Button>
			</>
		);
	}

	renderStatusBadge(e: any) {
		if (e.availableToBuy) {
			return <span className={`row-badge status-public`}>Available</span>
		} else {
			return <span className={`row-badge status-hidden`}>Not Available</span>
		}
	}

	renderVisibleStatusBadge(e: any) {
		if (e.isActive) {
			return <span className={`row-badge status-public`}>Active</span>
		} else {
			return <span className={`row-badge status-hidden`}>Inactive</span>
		}
	}

	async saveArea() {
		await this.setState({ isLoading: true });
		if (this.state.selectedArea.id === undefined || this.state.selectedArea.id === -1) { // Create new
			const res = await (await DeliveryAreaService.createSingle(this.state.selectedArea)).json();
			if (res && res.error) {
				this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
				this.setState({ isLoading: false });
			} else {
				this.setState({ isLoading: false, selectedArea: res, showAreaInfoModal: false})
				this.getAreas();
			};
		} else { // Save existing
			const res = await (await DeliveryAreaService.updateSingleById(this.state.selectedArea.id, this.state.selectedArea)).json();
			if (res && res.error) {
				this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
				this.setState({ isLoading: false });
			} else {
				this.setState({ isLoading: false, selectedArea: new DeliveryArea(), showAreaInfoModal: false })
				this.getAreas();
			};
		}
	}

	async saveBand() {
		await this.setState({ isLoading: true });
		if (this.state.selectedBand.id === undefined || this.state.selectedBand.id === -1) { // Create new
			const res = await (await DeliveryPriceBandService.createSingle(this.state.selectedBand)).json();
			if (res && res.error) {
				this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
				this.setState({ isLoading: false });
			} else {
				this.setState({ isLoading: false, selectedBand: res, showBandInfoModal: false })
				
			};
		} else { // Save existing
			const res = await (await DeliveryPriceBandService.updateSingleById(this.state.selectedBand.id, this.state.selectedBand)).json();
			if (res && res.error) {
				this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
				this.setState({ isLoading: false });
			} else {
				this.setState({ isLoading: false, selectedBand: new DeliveryPriceBand(), showBandInfoModal: false })
				this.getBands();
			};
		}
	}

	async uploadImage(data: any, property: string) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", data);
		uploadData.append("mimeTypeKey", "Images");
		const response = await (await FileService.upload(uploadData)).json();
		let tempDish = { ...this.state.selectedBand };
		// @ts-ignore // Required to be able to change the values
		tempDish[property] = `${process.env.REACT_APP_SERVER_STORAGEURL}/${containerName}/${response[0].location}`;
		await this.setState({ selectedBand: tempDish });
	}

	async removeImageBackground(data: any, property: string) {
		const formData = new FormData();
		formData.append("image_file", data.files[0]);
		const response = await (await ImageService.removeBackground(formData)).blob();
		await this.uploadImage(response, property);
	}

	textEditor(options:any){
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };
}