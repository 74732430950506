import { faPencil, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { MenuCategory } from "src/app/interfaces/Index";
import { MenuCategoryService, FileService, ImageService } from "src/app/services/Index";
import { colours } from "src/app/Styles";
import "./MenuCategories.css";

type Props = {};
type State = {
	dishCategories: MenuCategory[];
	isLoading: boolean;
	selectedCategory: MenuCategory;
	selectedCategories: MenuCategory[];
	showDeleteModal: boolean;
	showDeleteSelectionModal: boolean;
	showCategoryInfoModal: boolean;
	submitted: boolean;
};
export class MenuCategories extends React.Component<Props, State> {
	dataTable: any;
	toast: any;

	constructor(props: Props) {
		super(props);
		this.state = { dishCategories: [], isLoading: false, selectedCategory: new MenuCategory(), selectedCategories: [], showDeleteModal: false, showDeleteSelectionModal: false, showCategoryInfoModal: false, submitted: false };
	}

	categoryChange(e: any) {
		let selectedDish = { ...this.state.selectedCategory };
		// selectedDish.type = e.value;
		this.setState({ selectedCategory: selectedDish });
	}

	componentDidMount() {
		this.getDishCategories();
	}

	deleteDishFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="No" onClick={() => this.setState({ showDeleteModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Yes" onClick={(data) => this.deleteCategoryConfirm(data)}></Button>
			</>
		);
	}

	deleteDishesFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="No" onClick={() => this.setState({ showDeleteSelectionModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Yes" onClick={() => this.deleteSelectedDishesConfirm()}></Button>
			</>
		);
	}

	async deleteCategoryConfirm(rowData: any) {
		const res = await (await MenuCategoryService.updateSingleById(this.state.selectedCategory.id, this.state.selectedCategory)).json();
		if (res && res.error) {
			this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
			this.setState({ isLoading: false });
		} else {
			this.setState({ isLoading: false, selectedCategory: new MenuCategory(), showDeleteModal: false })
			this.toast.show({ closable: false, detail: "MenuItem Successfully Archived", life: 5000, severity: "warn", summary: "MenuItem Archived" });
			this.getDishCategories();
		};
	}

	deleteSelectedDishesConfirm() { }

	dishInfoFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => this.setState({ selectedCategory: new MenuCategory(), showCategoryInfoModal: false })}></Button>
				<Button className="p-button-text" icon="pi pi-check" label={this.state.selectedCategory.id === undefined || this.state.selectedCategory.id === -1 ? "Create" : "Save"} onClick={() => this.saveDish()}></Button>
			</>
		);
	}

	async getDishCategories() {
		this.setState({ isLoading: true });
		const res = await (await MenuCategoryService.getAll()).json();
		if (res && res.error) {
			this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
			this.setState({ isLoading: false });
		} else this.setState({ dishCategories: res, isLoading: false });
	}

	inputChange(e: any, name: string) {
		const val = e.value !== undefined ? e.value : (e.target !== undefined && e.target.value !== undefined) ? e.target.value : "";

		if (name === "offerDescription" && val.length > 10) {
			e.preventDefault();
			return false;
		}

		let selectedDish = { ...this.state.selectedCategory };
		// @ts-ignore // Required to be able to use a generic function to change the values
		selectedDish[label] = val;
		this.setState({ selectedCategory: selectedDish });
	}

	render() {
		return (
			<>
				<Row>
					<Col>
						<div className="card">
							<h5>Menu Items</h5>
							<Toolbar left={() => this.renderLeftToolbarButtons()} right={() => this.renderRightToolbarButtons()}></Toolbar>
							<DataTable stripedRows loading={this.state.isLoading} onSelectionChange={(e) => this.setState({ selectedCategories: e.value })} ref={(el) => this.dataTable = el} scrollable scrollHeight="flex" selection={this.state.selectedCategories} style={{ height: "calc(100vh - 17.5rem)" }} value={this.state.dishCategories}>
								<Column field="name" header="Name" sortable></Column>
								<Column header="Visible on Site" body={(rowData: any) => this.renderVisibleStatusBadge(rowData)} sortable></Column>
								<Column style={{ width: 75 }} body={(rowData) => (
									<div className="actions">
										<Button onClick={() => this.renderDialog(rowData)} style={{ backgroundColor: colours.buttons.edit, borderColor: colours.buttons.edit, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
											<FontAwesomeIcon icon={faPencil} style={{ color: colours.white }} />
										</Button>
										<Button onClick={() => this.setState({ selectedCategory: rowData, showDeleteModal: true })} style={{ backgroundColor: colours.buttons.delete, borderColor: colours.buttons.delete, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
											<FontAwesomeIcon icon={faTrashAlt} style={{ color: colours.black }} />
										</Button>
									</div>
								)}></Column>
							</DataTable>
							<Dialog className="p-fluid" footer={() => this.dishInfoFooter()} header="MenuItem Details" modal onHide={() => this.setState({ showCategoryInfoModal: false })} style={{ width: "75%", height: "85vh" }} visible={this.state.showCategoryInfoModal}>
								<TabView className="tabview-header-icon">
									<TabPanel header="Basic Information" leftIcon="pi pi-info-circle">
										<Row>
											<div className="field">
												<label htmlFor="name">Name</label>
												<InputText autoFocus className={classNames({ "p-invalid": this.state.submitted && !this.state.selectedCategory.name })} id="name" onChange={(e) => this.inputChange(e, "name")} required value={this.state.selectedCategory.name} />
												{this.state.submitted && !this.state.selectedCategory.name &&
													<small className="p-invalid">Name is required.</small>
												}
											</div>
										</Row>
										<Row>
											<div className="field">
												<label htmlFor="description">More Information</label>
												<InputTextarea id="description" onChange={(e) => this.inputChange(e, "description")} required rows={3} value={this.state.selectedCategory.moreInformation} />
											</div>
										</Row>
										<Row>
											<Col>
												<div className="field">
													<label htmlFor="rank">Rank</label>
													<InputNumber id="rank" onChange={(e) => this.inputChange(e, "rank")} required value={this.state.selectedCategory.rank} />
												</div>
											</Col>
											<Col>
												<div className="field">
													<label htmlFor="displayOnSite" className="switchSpacing">Display on site</label>
													<InputSwitch id="displayOnSite" onChange={(e) => this.inputChange(e, "displayOnSite")} required checked={this.state.selectedCategory.displayOnSite} />
												</div>
											</Col>
										</Row>
									</TabPanel>
									<TabPanel header="Image" leftIcon="pi pi-image">
										<Row>
											<Col>
												{this.state.selectedCategory.pictureUrl &&
													<img alt={this.state.selectedCategory.pictureUrl} className="mt-0 mx-auto mb-3 block shadow-2" src={this.state.selectedCategory.pictureUrl} width="50%" />
												}
											</Col>
										</Row>
										<Row>
											<Col>
												<div className="field" style={{ marginBottom: 10 }}>
													<label htmlFor="price">Upload new image</label>
													<FileUpload accept="image/*" auto mode="basic" customUpload name="picture" style={{ marginBottom: 10 }} uploadHandler={(data) => this.removeImageBackground(data, "picture")}></FileUpload>
												</div>
											</Col>
										</Row>
									</TabPanel>
								</TabView>
							</Dialog>
							<Dialog footer={() => this.deleteDishFooter()} header="Confirm" modal onHide={() => this.setState({ showDeleteModal: false })} style={{ width: 550 }} visible={this.state.showDeleteModal}>
								<div className="d-flex align-items-center justify-content-center">
									<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem", marginRight: "0.5rem" }} />
									{this.state.selectedCategory &&
										<span>Are you sure you want to archive <b>{this.state.selectedCategory.name}</b>?</span>
									}
								</div>
							</Dialog>
						</div>
					</Col>
				</Row>
				<Toast ref={(el) => this.toast = el}></Toast>
			</>
		);
	}

	renderDialog(rowData: any) {
		this.setState({ selectedCategory: rowData, showCategoryInfoModal: true })
	}

	renderLeftToolbarButtons() {
		return (
			<>
				<Button icon="pi pi-plus" label="New" style={{ backgroundColor: colours.buttons.add, borderColor: colours.buttons.add, marginRight: 10 }} onClick={() => this.setState({ selectedCategory: new MenuCategory(), showCategoryInfoModal: true })}></Button>
			</>
		);
	}

	renderRightToolbarButtons() {
		return (
			<>
				<Button icon="pi pi-download" label="Export" style={{ backgroundColor: colours.buttons.export, borderColor: colours.buttons.export, marginLeft: 10 }} onClick={() => this.dataTable.exportCSV()}></Button>
			</>
		);
	}

	renderStatusBadge(e: any) {
		if (e.availableToBuy) {
			return <span className={`row-badge status-public`}>Available</span>
		} else {
			return <span className={`row-badge status-hidden`}>Not Available</span>
		}
	}

	renderVisibleStatusBadge(e: any) {
		if (e.displayOnSite) {
			return <span className={`row-badge status-public`}>Visible</span>
		} else {
			return <span className={`row-badge status-hidden`}>Hidden</span>
		}
	}

	async saveDish() {
		await this.setState({ isLoading: true });
		if (this.state.selectedCategory.id === undefined || this.state.selectedCategory.id === -1) { // Create new
			const res = await (await MenuCategoryService.createSingle(this.state.selectedCategory)).json();
			if (res && res.error) {
				this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
				this.setState({ isLoading: false });
			} else {
				this.setState({ isLoading: false, selectedCategory: new MenuCategory(), showCategoryInfoModal: false })
				this.getDishCategories();
			};
		} else { // Save existing
			const res = await (await MenuCategoryService.updateSingleById(this.state.selectedCategory.id, this.state.selectedCategory)).json();
			if (res && res.error) {
				this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
				this.setState({ isLoading: false });
			} else {
				this.setState({ isLoading: false, selectedCategory: new MenuCategory(), showCategoryInfoModal: false })
				this.getDishCategories();
			};
		}
	}

	async uploadImage(data: any, property: string) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", data);
		uploadData.append("mimeTypeKey", "Images");
		const response = await (await FileService.upload(uploadData)).json();
		let tempDish = { ...this.state.selectedCategory };
		// @ts-ignore // Required to be able to change the values
		tempDish[property] = `${process.env.REACT_APP_SERVER_STORAGEURL}/${containerName}/${response[0].location}`;
		await this.setState({ selectedCategory: tempDish });
	}

	async removeImageBackground(data: any, property: string) {
		const formData = new FormData();
		formData.append("image_file", data.files[0]);
		const response = await (await ImageService.removeBackground(formData)).blob();
		await this.uploadImage(response, property);
	}
}