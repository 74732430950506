import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DiscountCode } from "src/app/interfaces/Index";
import { DiscountCodeService } from "src/app/services/Index";
import { colours } from "src/app/Styles";
import { DiscountType } from "src/app/enums/Index";
import { GetPropertiesFromEnum } from "src/app/helpers/Index";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";

type Props = {};
type State = {
	discountCodes: DiscountCode[];
	isLoading: boolean;
	selectedCoupon: DiscountCode;
	showDiscountCodeInfoModal: boolean;
	validationErrors: any;
};

export class Coupons extends React.Component<Props, State> {
	dataTable: any;
	toast: any;

	constructor(props: Props) {
		super(props);
		this.state = {discountCodes: [], isLoading: false, selectedCoupon: new DiscountCode(), showDiscountCodeInfoModal: false, validationErrors: null};
	}

	componentDidMount() {
		this.getDiscountCodes();
	}

	discountCodeInfoFooter() {
		return (
			<>
				<Button className="p-button-text" icon="pi pi-times" label="Cancel" onClick={() => this.setState({selectedCoupon: new DiscountCode(), showDiscountCodeInfoModal: false})}></Button>
				<Button className="p-button-text" icon="pi pi-check" label="Save" onClick={() => this.saveDiscountCode()}></Button>
			</>
		);
	}

	async getDiscountCodes() {
		this.setState({isLoading: true});
		const res = await (await DiscountCodeService.getAll()).json();
		if (res && res.error) {
			this.toast.show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
			this.setState({isLoading: false});
		}
		this.setState({discountCodes: res, isLoading: false});
	}

	inputChange(e: any, label: string) {
		const val = e.value !== undefined ? e.value : (e.target !== undefined && e.target.value !== undefined) ? e.target.value : "";
		let selectedCoupon = {...this.state.selectedCoupon};
		// @ts-ignore // Required to be able to use a generic function to change the values
		selectedCoupon[label] = val;
		this.setState({selectedCoupon});
	}

	render() {
		return (
			<>
				<Row>
					<Col>
						<div className="card">
							<h5>Coupons</h5>
							<Toolbar left={() => this.renderLeftToolbarButtons()} right={() => this.renderRightToolbarButtons()}></Toolbar>
							<DataTable loading={this.state.isLoading} ref={(el) => this.dataTable = el} filterDisplay="row" scrollable scrollHeight="flex" style={{height: "calc(100vh - 17.5rem)"}} value={this.state.discountCodes}>
								<Column field="discountCodeName" header="Name" filter sortable></Column>
								<Column field="code" header="Code" sortable filter></Column>
								<Column header="Status" body={(rowData: any) => this.renderStatusBadge(rowData)} sortable></Column>
								<Column style={{width: 75}} body={(rowData) => (
									<>
										<Button onClick={() => this.setState({selectedCoupon: rowData, showDiscountCodeInfoModal: true, validationErrors: null})} style={{backgroundColor: colours.buttons.edit, borderColor: colours.buttons.edit, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30}}>
											<FontAwesomeIcon icon={faPencil} style={{color: colours.white}}/>
										</Button>
									</>
								)}></Column>
							</DataTable>
							<Dialog className="p-fluid" footer={() => this.discountCodeInfoFooter()} header="Coupon Details" modal onHide={() => this.setState({showDiscountCodeInfoModal: false})} style={{width: "75%"}} visible={this.state.showDiscountCodeInfoModal}>
								<Row>
									<Col>
									<div className="field">
									<label htmlFor="discountCodeName">Name</label>
									<InputText autoFocus className={classNames({"p-invalid": this.state.validationErrors !== null && this.state.validationErrors.discountCodeName})} id="discountCodeName" onChange={(e) => this.inputChange(e, "discountCodeName")} required value={this.state.selectedCoupon.discountCodeName}/>
									{this.state.validationErrors != null && this.state.validationErrors.discountCodeName &&
										<small className="p-error">{this.state.validationErrors.discountCodeName}</small>
									}
								</div>
									</Col>
									<Col>
									<div className="field">
									<label htmlFor="code">Code</label>
									<InputText autoFocus className={classNames({"p-invalid": this.state.validationErrors !== null && this.state.validationErrors.code})} id="code" onChange={(e) => this.inputChange(e, "code")} required value={this.state.selectedCoupon.code}/>
									{this.state.validationErrors !== null && this.state.validationErrors.code &&
										<small className="p-error">{this.state.validationErrors.code}</small>
									}
								</div>
									</Col>
								</Row>
								<div className="field">
									<label htmlFor="promoText">Promotional Text</label>
									<InputTextarea cols={20} id="promoText" onChange={(e) => this.inputChange(e, "promoText")} required rows={2} value={this.state.selectedCoupon.promoText}/>
									{this.state.validationErrors !== null && this.state.validationErrors.promoText &&
										<small className="p-error">{this.state.validationErrors.promoText}</small>
									}
								</div>
								<Row>
									<Col>
										<div className="field">
											<label htmlFor="discountPercentage">Discount Percentage</label>
											<InputNumber autoFocus id="discountPercentage" onChange={(e) => this.inputChange(e, "discountPercentage")} required value={this.state.selectedCoupon.discountPercentage}/>
										</div>
									</Col>
									<Col>
										<div className="field">
											<label htmlFor="discountAmount">Discount Amount</label>
											<InputNumber autoFocus id="discountAmount"  mode="currency" currency="GBP" locale="en-GB" maxFractionDigits={2} onChange={(e) => this.inputChange(e, "discountAmount")} required value={this.state.selectedCoupon.discountAmount}/>
											{this.state.validationErrors != null && this.state.validationErrors.discountAmount &&
												<small className="p-error">{this.state.validationErrors.discountAmount}</small>
											}
										</div>
									</Col>
									<Col>
									<div className="field col">
										<label htmlFor="freeDelivery">Free Delivery?</label>
										<InputSwitch checked={this.state.selectedCoupon.freeDelivery} id="freeDelivery" onChange={(e) => this.inputChange(e, "freeDelivery")} />
									</div>
								</Col>
								</Row>
								<Row>
									<Col>
										<div className="field">
											<label htmlFor="startDate">Valid From</label>
											<Calendar id="startDate" onChange={(e) => this.inputChange(e, "startDate")} required value={new Date(this.state.selectedCoupon.startDate)} dateFormat="dd/mm/yy" showIcon showButtonBar className={this.state.validationErrors != null && this.state.validationErrors.startDate ? "p-invalid" : ""}/>
											{this.state.validationErrors != null && this.state.validationErrors.startDate &&
												<small className="p-error">{this.state.validationErrors.startDate}</small>
											}
										</div>
									</Col>
									<Col>
										<div className="field">
											<label htmlFor="endDate">Valid To</label>
											<Calendar id="endDate" onChange={(e) => this.inputChange(e, "endDate")} required value={new Date(this.state.selectedCoupon.endDate)} dateFormat="dd/mm/yy" showIcon showButtonBar minDate={new Date(this.state.selectedCoupon.endDate)} className={this.state.validationErrors != null && this.state.validationErrors.endDate ? "p-invalid" : ""}/>
											{this.state.validationErrors != null && this.state.validationErrors.endDate &&
												<small className="p-error">{this.state.validationErrors.endDate}</small>
											}
										</div>
									</Col>
								</Row>
								<label htmlFor="allowedDaysSection">Allowed Days</label>
								<Row>
								<Col>
									<div className="field col">
										<label htmlFor="allowedOnMondays">Monday</label>
										<InputSwitch checked={this.state.selectedCoupon.allowedOnMondays} id="allowedOnMondays" onChange={(e) => this.inputChange(e, "allowedOnMondays")} />
									</div>
								</Col>
								<Col>
									<div className="field col">
										<label htmlFor="allowedOnTuesdays">Tuesday</label>
										<InputSwitch checked={this.state.selectedCoupon.allowedOnTuesdays} id="allowedOnTuesdays" onChange={(e) => this.inputChange(e, "allowedOnTuesdays")} />
									</div>
								</Col>
								<Col>
									<div className="field col">
										<label htmlFor="allowedOnWednesdays">Wednesday</label>
										<InputSwitch checked={this.state.selectedCoupon.allowedOnWednesdays} id="allowedOnWednesdays" onChange={(e) => this.inputChange(e, "allowedOnWednesdays")} />
									</div>
								</Col>
								<Col>
									<div className="field col">
										<label htmlFor="allowedOnThursdays">Thursday</label>
										<InputSwitch checked={this.state.selectedCoupon.allowedOnThursdays} id="allowedOnThursdays" onChange={(e) => this.inputChange(e, "allowedOnThursdays")} />
									</div>
								</Col>
								<Col>
									<div className="field col">
										<label htmlFor="allowedOnFridays">Friday</label>
										<InputSwitch checked={this.state.selectedCoupon.allowedOnFridays} id="allowedOnFridays" onChange={(e) => this.inputChange(e, "allowedOnFridays")} />
									</div>
								</Col>
								<Col>
									<div className="field col">
										<label htmlFor="allowedOnSaturdays">Saturday</label>
										<InputSwitch checked={this.state.selectedCoupon.allowedOnSaturdays} id="allowedOnSaturdays" onChange={(e) => this.inputChange(e, "allowedOnSaturdays")} />
									</div>
								</Col>
								<Col>
									<div className="field col">
										<label htmlFor="allowedOnSundays">Sunday</label>
										<InputSwitch checked={this.state.selectedCoupon.allowedOnSundays} id="allowedOnSundays" onChange={(e) => this.inputChange(e, "allowedOnSundays")} />
									</div>
								</Col>
							</Row>
							</Dialog>
						</div>
					</Col>
				</Row>
				<Toast ref={(el) => this.toast = el}></Toast>
			</>
		);
	}

	renderLeftToolbarButtons() {
		return (
			<>
				<Button icon="pi pi-plus" label="New" style={{backgroundColor: colours.buttons.add, borderColor: colours.buttons.add, marginRight: 10}} onClick={() => this.setState({selectedCoupon: new DiscountCode(), showDiscountCodeInfoModal: true, validationErrors: null})}></Button>
			</>
		);
	}

	renderRightToolbarButtons() {
		return (
			<>
				<Button icon="pi pi-download" label="Export" style={{backgroundColor: colours.buttons.export, borderColor: colours.buttons.export, marginLeft: 10}} onClick={() => this.dataTable.exportCSV()}></Button>
			</>
		);
	}

	renderStatusBadge(e: any) {
		const now = new Date();
		const from = new Date(e.startDate);
		const to = new Date(e.endDate);

		if (from > now) {
			return <span className={`row-badge status-pending`}>Future</span>
		}

		if (to < now) {
			return <span className={`row-badge status-hidden`}>Expired</span>
		}

		return <span className={`row-badge status-public`}>Current</span>
	}

	async saveDiscountCode() {
		this.setState({validationErrors: null});

		let discountCode = this.state.selectedCoupon;

		const creatingNew = (discountCode.id === undefined || discountCode.id <= 0);
		const response = creatingNew ? await DiscountCodeService.createSingle(discountCode) : await DiscountCodeService.updateSingleById(discountCode.id, discountCode);
		const responseDetails = await response.json();

		if (response.ok) {
			this.toast.show({closable: false, detail: "Your Coupon has been saved", life: 5000, severity: "success", summary: "Coupon saved!"});
			this.setState({showDiscountCodeInfoModal: false});
			this.getDiscountCodes();
			return;
		}

		if (response.status === 422) {
			this.toast.show({closable: false, detail: "Please fix the validation errors and try again", life: 5000, severity: "error", summary: "Validation Failed"});
			this.setState({validationErrors: responseDetails.validationErrors});
			return;
		}

		this.toast.show({closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!"});
		console.log(responseDetails.error);
	}
}