import { AppSettingsService } from "src/app/services/Index";

export async function FetchInterceptor(url: string, init: RequestInit) {
	if (!url.includes("/Auth/")) {
		const token = await AppSettingsService.get("accessToken");
		const newHeaders = {"Authorization": `Bearer ${token}`, ...init.headers};
		init = {...init, headers: {...newHeaders}};
	}
	const serverResponse = await fetch(url, init);
	return serverResponse;
}