import { Guid } from "guid-typescript";
import { OrderStatus, CustomerAddressType } from "../enums/Index";
import { CustomerAddress } from "./CustomerAddress";
import { OrderPaymentMethod } from "../enums/OrderPaymentMethod";

export interface ChangeOrderStatus {
	order: Guid;
	status: OrderStatus;
	reason: string;
}

export class ChangeOrderStatus implements ChangeOrderStatus {
	constructor(data?: Partial<ChangeOrderStatus>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}