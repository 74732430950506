import { faEye } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Tree, TreeExpandedKeysType } from 'primereact/tree';
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DeliveryMethod } from "src/app/enums/DeliveryMethod";
import { OrderStatus } from "src/app/enums/OrderStatus";
import { FormatNumber } from "src/app/helpers/Index";
import { RestaurantSettings, Order, OrderItem, ChangeOrderStatus, MenuItem, MenuItemOption } from "src/app/interfaces/Index";
import { RestaurantSettingsService, OrderService } from "src/app/services/Index";
import { colours } from "src/app/Styles";
import "./Orders.css";
import { OrderType } from "src/app/enums/OrderType";
import { OrderPaymentMethod } from "src/app/enums/OrderPaymentMethod";
import { TreeNodeItem } from "src/app/interfaces/TreeNode";

type Props = {};
type State = {
	expandedNodes?: TreeExpandedKeysType;
	isLoading: boolean;
	nodes: TreeNodeItem[];
	orderDetailsLoading: boolean;
	orderItem: OrderItem;
	orders: Order[];
	ordersSeven: Order[];
	ordersToday: Order[];
	selectedOrder: Order;
	showOrderInfoModal: boolean;
	submitted: boolean;
};
export class Orders extends React.Component<Props, State> {
	dataTable: any;
	toast: any;

	constructor(props: Props) {
		super(props);
		this.state = { isLoading: false, nodes: [], orderDetailsLoading: false, orderItem: new OrderItem(), orders: [], ordersSeven: [], ordersToday: [], selectedOrder: new Order(), showOrderInfoModal: false, submitted: false };
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		this.getTodaysOrders();
		this.get7DaysOrders();
		this.get30DaysOrders();
		this.setState({ isLoading: false });
	}

	async get7DaysOrders() {
		const res = await (await OrderService.sevenDays()).json();
		let orders: Order[] = res;
		if (res && res.error) this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
		else this.setState({ ordersSeven: res }, () => this.state.selectedOrder.id && this.state.selectedOrder.id > -1 ? this.setState({ selectedOrder: this.state.orders.find((order) => order.id === this.state.selectedOrder.id)! }) : null);
	}

	async get30DaysOrders() {
		const res = await (await OrderService.thirtyDays()).json();
		let orders: Order[] = res;
		if (res && res.error) this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
		else this.setState({ orders: res }, () => this.state.selectedOrder.id && this.state.selectedOrder.id > -1 ? this.setState({ selectedOrder: this.state.orders.find((order) => order.id === this.state.selectedOrder.id)! }) : null);
	}

	async getTodaysOrders() {
		const res = await (await OrderService.today()).json();
		let orders: Order[] = res;
		if (res && res.error) this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
		else this.setState({ ordersToday: res }, () => this.state.selectedOrder.id && this.state.selectedOrder.id > -1 ? this.setState({ selectedOrder: this.state.orders.find((order) => order.id === this.state.selectedOrder.id)! }) : null);
	}

	async orderApprove() {
		this.setState({ orderDetailsLoading: true });
		let newStatus = new ChangeOrderStatus();
		newStatus.order = this.state.selectedOrder.lookupId;
		newStatus.status = OrderStatus.Accepted;
		const res = await (await OrderService.changeOrderStatus(newStatus)).json();
		if (res && res.error) this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
		else {
			this.toast.show({ closable: false, detail: "You have accepted the order, the customer will be notified.", life: 5000, severity: "success", summary: "Order accepted!" });
			this.getTodaysOrders();
		}
		this.setState({ orderDetailsLoading: false });
	}

	async orderComplete() {
		this.setState({ orderDetailsLoading: true });
		let newStatus = new ChangeOrderStatus();
		newStatus.order = this.state.selectedOrder.lookupId;
		newStatus.status = OrderStatus.Completed;
		const res = await (await OrderService.changeOrderStatus(newStatus)).json();
		if (res && res.error) this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
		else {
			this.toast.show({ closable: false, detail: "Order collected, the customer will be notified.", life: 5000, severity: "success", summary: "Order collected!" });
			this.setState({ selectedOrder: new Order(), showOrderInfoModal: false }, () => this.getTodaysOrders());
		}
		this.setState({ orderDetailsLoading: false });
	}

	async orderReady() {
		this.setState({ orderDetailsLoading: true });
		let newStatus = new ChangeOrderStatus();
		newStatus.order = this.state.selectedOrder.lookupId;
		newStatus.status = OrderStatus.ReadyForCollection;
		const res = await (await OrderService.changeOrderStatus(newStatus)).json();
		if (res && res.error) this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
		else {
			this.toast.show({ closable: false, detail: "Order ready, the customer/courier will be notified.", life: 5000, severity: "success", summary: "Order Ready!" });
			this.getTodaysOrders();
		}
		this.setState({ orderDetailsLoading: false });
	}

	async orderReject() {
		this.setState({ orderDetailsLoading: true });
		let newStatus = new ChangeOrderStatus();
		newStatus.order = this.state.selectedOrder.lookupId;
		newStatus.status = OrderStatus.Declined;
		newStatus.reason = "Rejected By Kitchen (Web)"
		const res = await (await OrderService.changeOrderStatus(newStatus)).json();
		if (res && res.error) this.toast.show({ closable: false, detail: "Please try again later.", life: 5000, severity: "error", summary: "Something went wrong!" });
		else {
			this.toast.show({ closable: false, detail: "You have rejected the order, the customer will be notified.", life: 5000, severity: "success", summary: "Order rejected!" });
			this.setState({ selectedOrder: new Order(), showOrderInfoModal: false }, () => this.getTodaysOrders());
		}
		this.setState({ orderDetailsLoading: false });
	}

	orderView(rowData: any) {
		let order: Order = rowData;
		this.viewItems(order);
		this.setState({ selectedOrder: rowData, showOrderInfoModal: true });
	}

	render() {
		return (
			<>
				<Row>
					<Col>
						<div className="card" style={{ paddingTop: "1.5rem" }}>
							<h5>Orders</h5>
							<TabView className="tabview-header-icon">
								<TabPanel header="Today" leftIcon="pi pi-calendar">
									<Toolbar right={() => this.renderRightToolbarButtons()}></Toolbar>
									<DataTable stripedRows ref={(el) => this.dataTable = el} filterDisplay="row" scrollable scrollHeight="flex" style={{ height: "calc(100vh - 17.5rem)" }} value={this.state.ordersToday}>
										<Column field="customerName" header="Name" filter sortable body={(data: Order) => data.customerName}></Column>
										<Column field="orderTotal" header="Total" sortable body={(data: Order) => FormatNumber(data.grandTotal, true)}></Column>
										<Column header="Status" body={(rowData: any) => this.renderStatusBadge(rowData)} sortable></Column>
										<Column field="orderPaymentMothed" header="Method" sortable body={(rowData: Order) => OrderPaymentMethod[rowData.orderPaymentMethod].split(/(?=[A-Z])/).join(" ")}></Column>
										<Column field="orderType" header="Method" sortable body={(rowData: Order) => OrderType[rowData.orderType].split(/(?=[A-Z])/).join(" ")}></Column>
										<Column style={{ width: 75 }} body={(rowData) => (
											<div className="actions">
												<Button onClick={() => { this.orderView(rowData) }} style={{ backgroundColor: colours.buttons.view, borderColor: colours.buttons.view, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
													<FontAwesomeIcon icon={faEye} style={{ color: colours.white }}></FontAwesomeIcon>
												</Button>
											</div>
										)}></Column>
									</DataTable>
								</TabPanel>
								<TabPanel header="Last 7 Days" leftIcon="pi pi-calendar">
									<Toolbar right={() => this.renderRightToolbarButtons()}></Toolbar>
									<DataTable stripedRows ref={(el) => this.dataTable = el} filterDisplay="row" scrollable scrollHeight="flex" style={{ height: "calc(100vh - 17.5rem)" }} value={this.state.ordersSeven}>
										<Column field="customerName" header="Name" filter sortable body={(data: Order) => data.customerName}></Column>
										<Column field="orderTotal" header="Total" sortable body={(data: Order) => FormatNumber(data.grandTotal, true)}></Column>
										<Column header="Status" body={(rowData: any) => this.renderStatusBadge(rowData)} sortable></Column>
										<Column field="orderPaymentMothed" header="Method" sortable body={(rowData: Order) => OrderPaymentMethod[rowData.orderPaymentMethod].split(/(?=[A-Z])/).join(" ")}></Column>
										<Column field="orderType" header="Method" sortable body={(rowData: Order) => OrderType[rowData.orderType].split(/(?=[A-Z])/).join(" ")}></Column>
										<Column style={{ width: 75 }} body={(rowData) => (
											<div className="actions">
												<Button onClick={() => { this.orderView(rowData) }} style={{ backgroundColor: colours.buttons.view, borderColor: colours.buttons.view, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
													<FontAwesomeIcon icon={faEye} style={{ color: colours.white }}></FontAwesomeIcon>
												</Button>
											</div>
										)}></Column>
									</DataTable>
								</TabPanel>
								<TabPanel header="Last 30 Days" leftIcon="pi pi-calendar">
									<Toolbar right={() => this.renderRightToolbarButtons()}></Toolbar>
									<DataTable stripedRows ref={(el) => this.dataTable = el} filterDisplay="row" scrollable scrollHeight="flex" style={{ height: "calc(100vh - 17.5rem)" }} value={this.state.orders}>
										<Column field="customerName" header="Name" filter sortable body={(data: Order) => data.customerName}></Column>
										<Column field="orderTotal" header="Total" sortable body={(data: Order) => FormatNumber(data.grandTotal, true)}></Column>
										<Column header="Status" body={(rowData: any) => this.renderStatusBadge(rowData)} sortable></Column>
										<Column field="orderPaymentMothed" header="Method" sortable body={(rowData: Order) => OrderPaymentMethod[rowData.orderPaymentMethod].split(/(?=[A-Z])/).join(" ")}></Column>
										<Column field="orderType" header="Method" sortable body={(rowData: Order) => OrderType[rowData.orderType].split(/(?=[A-Z])/).join(" ")}></Column>
										<Column style={{ width: 75 }} body={(rowData) => (
											<div className="actions">
												<Button onClick={() => { this.orderView(rowData) }} style={{ backgroundColor: colours.buttons.view, borderColor: colours.buttons.view, height: 30, justifyContent: "center", margin: 2, padding: 0, width: 30 }}>
													<FontAwesomeIcon icon={faEye} style={{ color: colours.white }}></FontAwesomeIcon>
												</Button>
											</div>
										)}></Column>
									</DataTable>
								</TabPanel>
							</TabView>
							<Dialog className="p-fluid" draggable={false} header="Order Details" modal onHide={() => this.setState({ selectedOrder: new Order(), showOrderInfoModal: false })} style={{ width: "75%" }} visible={this.state.showOrderInfoModal}>
								<TabView className="tabview-header-icon">
									<TabPanel header="Key Information" leftIcon="pi pi-info-circle">
										<Row style={{ marginBottom: "1rem" }}>
											<h6>Order Actions</h6>
											<Col>
												<Button disabled={this.state.selectedOrder.orderStatus != OrderStatus.Pending} className="rejectButton" style={{ backgroundColor: colours.buttons.cancel, borderColor: colours.buttons.cancel, marginRight: 10 }} label="Reject" onClick={() => this.orderReject()}></Button>
											</Col>
											<Col>
												<Button disabled={this.state.selectedOrder.orderStatus != OrderStatus.Pending} className="nextStepButton" style={{ backgroundColor: colours.buttons.nextStep, borderColor: colours.buttons.nextStep }} label="Confirm" onClick={() => this.orderApprove()}></Button>
											</Col>
											<Col>
												<Button disabled={this.state.selectedOrder.orderStatus != OrderStatus.Accepted || (this.state.selectedOrder.orderType === OrderType.Collection)} className="nextStepButton" style={{ backgroundColor: colours.buttons.nextStep, borderColor: colours.buttons.nextStep }} label="Ready For Collection" onClick={() => this.orderReady()}></Button>
											</Col>
											<Col>
												<Button disabled={this.state.selectedOrder.orderStatus != OrderStatus.Accepted && this.state.selectedOrder.orderType === OrderType.Delivery} className="nextStepButton" style={{ backgroundColor: colours.buttons.complete, borderColor: colours.buttons.complete }} label="Out For Delivery" onClick={() => this.orderComplete()}></Button>
											</Col>
										</Row>
										<hr />
										<Row>
											<h6>Order Details</h6>
											<Col>
												<div className="field" style={{ marginBottom: 10 }}>
													<label htmlFor="customerName">Customer Name</label>
													<InputText id="customerName" readOnly value={this.state.selectedOrder.customerName} />
												</div>
											</Col>
											<Col>
												<div className="field" style={{ marginBottom: 10 }}>
													<label htmlFor="orderStatus">Order Status</label>
													<InputText id="orderStatus" readOnly value={OrderStatus[(this.state.selectedOrder.orderStatus)]?.split(/(?=[A-Z])/).join(" ")} />
												</div>
											</Col>
										</Row>
										<Row>
											<Col>
												<div className="field" style={{ marginBottom: 10 }}>
													<label htmlFor="itemTotal">Sub Total</label>
													<InputNumber currency="GBP" id="itemTotal" locale="en-GB" mode="currency" readOnly value={this.state.selectedOrder.itemTotal} />
												</div>
											</Col>
										</Row>
										<Row>
											<Col>
												<h6>Order Items</h6>
												<Tree value={this.state.nodes} expandedKeys={this.state.expandedNodes} onToggle={(e) => this.setState({ expandedNodes: e.value })} className="w-full md:w-30rem" />
											</Col>
										</Row>
									</TabPanel>
									<TabPanel header="Financial Information" leftIcon="pi pi-money-bill">
										<Row>
											<Col>
												<div className="field">
													<label htmlFor="itemsValue">Sub Total</label>
													<InputNumber currency="GBP" id="itemsValue" locale="en-GB" mode="currency" readOnly value={this.state.selectedOrder.itemTotal} />
												</div>
											</Col>
											<Col>
												<div className="field">
													<label htmlFor="grandTotal">Total</label>
													<InputNumber currency="GBP" id="grandTotal" locale="en-GB" mode="currency" readOnly value={this.state.selectedOrder.grandTotal} />
												</div>
											</Col>
										</Row>
										<Row>
											<Col>
												<div className="field">
													<label htmlFor="deliveryTotal">Delivery Fee</label>
													<InputNumber currency="GBP" id="deliveryTotal" locale="en-GB" mode="currency" readOnly value={this.state.selectedOrder.deliveryTotal} />
												</div>
											</Col>
											<Col>
												<div className="field">
													<label htmlFor="serviceCharge">Platform Fee (Paid by Customer)</label>
													<InputNumber currency="GBP" id="serviceCharge" locale="en-GB" mode="currency" readOnly value={this.state.selectedOrder.serviceCharge} />
												</div>
											</Col>
										</Row>
										<Row>
											<Col>
												<div className="field">
													<label htmlFor="chefPayment">Your Earnings</label>
													<InputNumber currency="GBP" id="chefPayment" locale="en-GB" mode="currency" readOnly value={this.state.selectedOrder.grandTotal - this.state.selectedOrder.serviceCharge} />
												</div>
											</Col>
											<Col>
												<div className="field">
													<label htmlFor="paymentMethod">Payment Method</label>
													<InputText id="paymentMethod" readOnly value={OrderPaymentMethod[(this.state.selectedOrder.orderPaymentMethod === undefined ? 0 : this.state.selectedOrder.orderPaymentMethod)]?.split(/(?=[A-Z])/).join(" ")} />
												</div>
											</Col>
										</Row>
										<hr />
										<Row>
											<Col>
												<label style={{ marginTop: ".5rem", marginBottom: "1rem" }} htmlFor="orderItemsBreakdown">Itemised Breakdown</label>
												<DataTable stripedRows id="orderItemsBreakdown" scrollable scrollHeight="350px" style={{ marginBottom: 10 }} value={this.state.selectedOrder.orderItems}>
													<Column field="name" header="Item" sortable></Column>
													<Column field="quantity" header="Quantity" sortable></Column>
													<Column field="itemValue" header="Item Value" sortable body={(data) => FormatNumber(data.itemValue, true)}></Column>
													<Column field="totalValue" header="Line Total" sortable body={(data) => FormatNumber(data.totalValue, true)}></Column>
												</DataTable>
											</Col>
										</Row>
									</TabPanel>

								</TabView>
								{this.state.orderDetailsLoading &&
									<div style={{ backgroundColor: colours.transparent, bottom: 0, left: 0, position: "absolute", right: 0, top: 0, zIndex: 99999 }}>
										<ProgressSpinner strokeWidth="5" style={{ height: "50%", left: "50%", maxHeight: 100, minHeight: 30, position: "absolute", strokeColor: colours.primary, top: "45%", transform: "translate(-50%, -50%)" }} />
									</div>
								}
							</Dialog>
						</div>
					</Col>
				</Row>
				<Toast ref={(el) => this.toast = el}></Toast>
			</>
		);
	}

	renderRightToolbarButtons() {
		return (
			<>
				<Button icon="pi pi-download" label="Export" style={{ backgroundColor: colours.buttons.export, borderColor: colours.buttons.export, marginLeft: 10 }} onClick={() => this.dataTable.exportCSV()}></Button>
			</>
		);
	}

	renderStatusBadge(e: any) {
		switch (e.orderStatus) {
			case 20:
				return <span className={`row-badge status-pending`}>Pending</span>
				break;
			case 30:
				return <span className={`row-badge status-accepted`}>Accepted</span>
				break;
			case 40:
				return <span className={`row-badge status-hidden`}>Declined</span>
				break;
			case 50:
				return <span className={`row-badge status-public`}>Out for Delivery</span>
				break;
			case 60:
				return <span className={`row-badge status-public`}>Ready to Collect</span>
				break;
			case 70:
				return <span className={`row-badge status-public`}>Completed</span>
				break;
			case 80:
				return <span className={`row-badge status-hidden`}>Cancelled</span>
				break;
		}
	}

	restoreDishConfirm(rowData: any) { }

	restoreselectedOrderesConfirm() { }

	viewItems(order: Order) {
		console.log("hit");
		let items = order.orderItems;
		let itemNumber: number = 0;
		let childNumber: number = 0;
		let nodeList: TreeNodeItem[] = []
		items.forEach(food => {
			let masterNode = new TreeNodeItem();
			let childNodes: TreeNodeItem[] = [];
			masterNode.label = food.name;
			masterNode.data = food.name;
			masterNode.key = itemNumber.toString();
			masterNode.icon = "pi pi-th-large";
			if (food.requiredChoice != undefined && food.requiredChoice != null) {
				let option = JSON.parse(food.requiredChoice);
				console.log("REQ")
				console.log(option);
				console.log(option.Name);
				let requiredNode: TreeNodeItem = new TreeNodeItem();
				requiredNode.label = option.Name;
				requiredNode.data = option.Name;
				requiredNode.key = itemNumber.toString() + "-" + childNumber.toString();
				requiredNode.icon = "pi pi-exclamation-circle";
				console.log("NODE")
				console.log(requiredNode);
				childNodes.push(requiredNode);
				childNumber = childNumber + 1
			}
			if (food.optionalExtras != undefined && food.optionalExtras != null) {
				let optionals: any[] = []
				optionals.push(JSON.parse(food.optionalExtras));
				console.log(optionals);
				optionals.forEach(option => {
					let optionNode = new TreeNodeItem();
					if (option.Price > 0) {
						optionNode.label = option.Name + " (+£" + (Math.round(option.Price * 100) / 100).toFixed(2) + ")";
						optionNode.data = option.Name + " (+£" + (Math.round(option.Price * 100) / 100).toFixed(2) + ")";
					} else {
						optionNode.label = option.Name;
						optionNode.data = option.Name;
					}
					optionNode.key = itemNumber.toString() + "-" + childNumber.toString();
					optionNode.icon = "pi pi-plus";
					childNodes.push(optionNode);
					childNumber = childNumber + 1;
				})
			}
			masterNode.children = childNodes;
			itemNumber = itemNumber + 1;
			nodeList.push(masterNode);
		});

		this.setState({ nodes: nodeList });
	}
}