export interface RefreshAccessToken {
	oldToken: string;
	refreshKey: string;
}

export class RefreshAccessToken implements RefreshAccessToken {
	oldToken = "";
	refreshKey = "";

	constructor(oldToken?: string, refreshKey?: string) {
		if (oldToken) this.oldToken = oldToken;
		if (refreshKey) this.refreshKey = refreshKey;
	}
}