import { Guid } from "guid-typescript";
import { FetchInterceptor } from "../helpers/Index";
import { CustomerAddress } from "../interfaces/Index";
import { Service } from "./Service";

const endpoint = "CustomerAddress";
export const CustomerAddressService = {
	endpoint,
	customerCreate: async (data: CustomerAddress) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Add`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	customerRemove: async (id: Guid, data: CustomerAddress) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Remove/${id}`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "DELETE"}),
	customerUpdate: async (id: Guid, data: CustomerAddress) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Edit/${id}`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "PATCH"}),	
	createSingle: async (data: CustomerAddress) => await Service.createSingle(endpoint, data),
	deleteSingleById: async (id: number) => await Service.deleteSingleById(endpoint, id),
	getAll: async () => await Service.getAll(endpoint),
	getSingleById: async (id: number) => await Service.getSingleById(endpoint, id),
	getSingleByLookupId: async (id: Guid) => await Service.getSingleByLookupId(endpoint, id),
	mine: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Mine`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	removeSingleByLookupId: async (id: Guid, data: CustomerAddress) => await Service.removeSingleByLookup(endpoint, id, data)
}