import { Guid } from "guid-typescript";
import { ChangePassword, OrderItem, RequestPasswordResetToken, StaffMember } from "../interfaces/Index";
import { Service } from "./Service";

import { FetchInterceptor } from "../helpers/Index";

const endpoint = "StaffMember";
export const StaffMemberService = {
	endpoint,
	adminCreate: async (data: StaffMember) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/AdminCreate`,{body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST",}),
	createSingle: async (data: StaffMember) => await Service.createSingle(endpoint, data),
	deleteSingleById: async (id: number) => await Service.deleteSingleById(endpoint, id),
	details: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Details`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	getAll: async () => await Service.getAll(endpoint),
	getSingleById: async (id: number) => await Service.getSingleById(endpoint, id),
	getSingleByLookupId: async (id: Guid) => await Service.getSingleByLookupId(endpoint, id),
	me: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Me`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	requestPasswordReset: async (data: RequestPasswordResetToken) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/RequestPasswordReset`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	requestPasswordResetForStaffMember: async (userId: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/RequestPasswordResetForStaffMember/${userId}`, {headers: {"Content-Type": "application/json"}, method: "POST"}),
	resetPasswordFromToken: async (data: RequestPasswordResetToken) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/ResetPasswordFromToken`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	toggleOnline: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/ToggleOnline`, {headers: {"Content-Type": "application/json"}, method: "POST"}),
	updateSingleById: async (id: number, data: StaffMember) => await Service.updateSingleById(endpoint, id, data),
	updatePassword: async (data: ChangePassword) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/UpdatePassword`,{body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST",})
}