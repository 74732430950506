import { Guid } from "guid-typescript";
import { FetchInterceptor } from "../helpers/Index";
import { CustomerDevice } from "../interfaces/Index";
import { Service } from "./Service";

const endpoint = "CustomerDevice";
export const CustomerDeviceService = {
	endpoint,
	checkRegistration: async (deviceId: string) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/CheckRegistration`, {body: JSON.stringify({deviceId}), headers: {"Content-Type": "application/json"}, method: "POST"}),
	createSingle: async (data: CustomerDevice) => await Service.createSingle(endpoint, data),
	deleteSingleById: async (id: number) => await Service.deleteSingleById(endpoint, id),
	getAll: async () => await Service.getAll(endpoint),
	getSingleById: async (id: number) => await Service.getSingleById(endpoint, id),
	getSingleByLookupId: async (id: Guid) => await Service.getSingleByLookupId(endpoint, id),
	removeRegistration: async (deviceId: string) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/RemoveRegistration`, {body: JSON.stringify({deviceId}), headers: {"Content-Type": "application/json"}, method: "POST"}),
	updateSingleById: async (id: number, data: CustomerDevice) => await Service.updateSingleById(endpoint, id, data)
}