import { Model } from "./Model";
import { AllowedOrderTypes } from "../enums/Index";

export interface RestaurantSettings extends Model {
    name?: string;
    description?: string;
    allergenDetails?: string;
    picture?: string;
    isOnline: boolean;
    addressLine1?: string;
    addressLine2?: string;
    postcode?: string;
    town?: string;
    phoneNumber?: string;
    emailAddress?: string;
    allowCashOnCollection: boolean;
    allowCashOnDelivery: boolean;
    allowOnlinePayments: boolean;
    guestAllowCashOnCollection: boolean;
    guestAllowCashOnDelivery: boolean;
    guestAllowOnlinePayments: boolean;
    minimumOrderValue: number;
    serviceCharge: number;
    allowedOrderTypes: AllowedOrderTypes;
    estimatedCollectionTime?: string;
    estimatedDeliveryTime?: string;
}

export class RestaurantSettings implements RestaurantSettings {
	constructor(data?: Partial<RestaurantSettings>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}