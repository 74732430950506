import { Model } from "./Model";

export interface ChangePassword extends Model {
	confirmNewPassword?: string;
	newPassword?: string;
	oldPassword?: string;
}

export class ChangePassword implements ChangePassword {
	constructor(data?: Partial<ChangePassword>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}