export enum OrderStatus {
	 /// <summary>
        /// The initial order status used while items are still being added, before checkout
        /// </summary>
        UnderConstruction = 0,
        /// <summary>
        /// Pay Online was picked during checkout, but payment hasn't been received yet
        /// </summary>
        AwaitingOnlinePayment = 10,
        /// <summary>
        /// Order is awaiting staff to approve or decline it
        /// </summary>
        Pending = 20,
        /// <summary>
        /// The order was accepted by staff and will be worked on
        /// </summary>
        Accepted = 30,
        /// <summary>
        /// Staff do not want to make this order
        /// </summary>
        Declined = 40,
        /// <summary>
        /// The order is ready and has been sent to the customer
        /// </summary>
        OutForDelivery = 50,
        /// <summary>
        /// The order is finished and ready for the customer to collect it
        /// </summary>
        ReadyForCollection = 60,
        /// <summary>
        /// The order is completed, i.e. has been delivered or collected
        /// </summary>
        Completed = 70,
        /// <summary>
        /// The order has been cancelled for whatever reason
        /// </summary>
        Cancelled = 80,
        /// <summary>
        /// The order has been abandoned, i.e. the user started putting it together then left the site for too long and the session expired
        /// </summary>
        Abandoned = 90
}