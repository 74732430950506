import { Guid } from "guid-typescript";
import { FetchInterceptor } from "../helpers/Index";
import { CustomerAddress, DeliveryArea, DeliveryPriceBand } from "../interfaces/Index";

import { Service } from "./Service";

const endpoint = "DeliveryPriceBand";
export const DeliveryPriceBandService = {
	endpoint,
	active: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Active`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	createSingle: async (data: DeliveryPriceBand) => await Service.createSingle(endpoint, data),
	deleteSingleById: async (id: number) => await Service.deleteSingleById(endpoint, id),
	forValue: async (value: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/ForValue/${value}`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	getAll: async () => await Service.getAll(endpoint),
	getSingleById: async (id: number) => await Service.getSingleById(endpoint, id),
	getSingleByLookupId: async (id: Guid) => await Service.getSingleByLookupId(endpoint, id),
	minimum: async (value: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Minimum`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	updateSingleById: async (id: number, data: DeliveryPriceBand) => await Service.updateSingleById(endpoint, id, data)
}