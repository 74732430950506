import { Model } from "./Model";

export interface MenuCategory extends Model {
    displayOnSite: boolean;
	name: string;
    moreInformation: string;
	rank: number;
    pictureUrl: string;
}

export class MenuCategory implements MenuCategory {

	constructor(data?: Partial<MenuCategory>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}