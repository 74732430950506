import { Guid } from "guid-typescript";
import { FetchInterceptor } from "../helpers/Index";
import { ChangePassword, Customer, RequestPasswordResetToken, ResetPasswordFromToken, SignUp, User } from "../interfaces/Index";

import { Service } from "./Service";

const endpoint = "Customer";
export const UserService = {
	endpoint,
	approveChef: async (id: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/ApproveChef/${id}`, {headers: {"Content-Type": "application/json"}, method: "POST"}),
	adminCreateCustomer: async (data: User) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/AdminCreate/Customer`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	blockUser: async (id: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/BlockUser/${id}`, {method: "POST"}),
	blockReportedUser: async (id: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/BlockReportedUser/${id}`, {method: "POST"}),
	chefSignUp: async (data: SignUp) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/ChefSignUp`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	chefUpdate: async (id: number, data: User) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/ChefUpdate/${id}`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "PATCH"}),
	customerUpdate: async (id: Guid, data: Customer) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/CustomerUpdate/${id}`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "PATCH"}),
	createSingle: async (data: User) => await Service.createSingle(endpoint, data),
	deleteSingleById: async (id: number) => await Service.deleteSingleById(endpoint, id),
	getAll: async () => await Service.getAll(endpoint),
	getSingleById: async (id: number) => await Service.getSingleById(endpoint, id),
	getSingleByLookupId: async (id: Guid) => await Service.getSingleByLookupId(endpoint, id),
	loginCheck: async (groupName: string) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/LoginCheck/${groupName}`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	me: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Me`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	requestPasswordReset: async (data: RequestPasswordResetToken) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/RequestPasswordReset`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	requestPasswordResetForUser: async (userId: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/RequestPasswordResetForUser/${userId}`, {headers: {"Content-Type": "application/json"}, method: "POST"}),
	resetPasswordFromToken: async (data: ResetPasswordFromToken) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/ResetPasswordFromToken`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	signUp: async (data: SignUp) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/SignUp`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	unblockUser: async (id: number) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/UnblockUser/${id}`, {method: "POST"}),
	updatePassword: async (data: ChangePassword) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/UpdatePassword`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	updateSingleById: async (id: number, data: User) => await Service.updateSingleById(endpoint, id, data),
	verifyEmailAddress: async (token: string) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/VerifyEmailAddress`, {body: JSON.stringify({EmailAddressVerificationCode: token}), headers: {"Content-Type": "application/json"}, method: "POST"})
}