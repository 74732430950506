import { Model } from "./Model";

export interface MenuItemOption extends Model {
    line: number;
    name: string;
    price: number;
    
    //menuCategory: MenuCategory | null;
}

export class MenuItemOption implements MenuItemOption {

	constructor(data?: Partial<MenuItemOption>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}