import { Guid } from "guid-typescript";
import { OrderItem, RestaurantSettings } from "../interfaces/Index";
import { Service } from "./Service";

import { FetchInterceptor } from "../helpers/Index";

const endpoint = "RestaurantSettings";
export const RestaurantSettingsService = {
	endpoint,
	createSingle: async (data: RestaurantSettings) => await Service.createSingle(endpoint, data),
	deleteSingleById: async (id: number) => await Service.deleteSingleById(endpoint, id),
	details: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Details`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	getAll: async () => await Service.getAll(endpoint),
	getSingleById: async (id: number) => await Service.getSingleById(endpoint, id),
	getSingleByLookupId: async (id: Guid) => await Service.getSingleByLookupId(endpoint, id),
	toggleOnline: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/ToggleOnline`, {headers: {"Content-Type": "application/json"}, method: "POST"}),
	updateSingleById: async (id: number, data: RestaurantSettings) => await Service.updateSingleById(endpoint, id, data)
}