import { GoogleLogin } from "@react-oauth/google";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React from "react";
import { Link, Navigate } from "react-router-dom";
import { HandleAuthTokenRefreshTimer } from "src/app/helpers/Index";
import { LoginDetails, SSOLoginDetails } from "src/app/interfaces/Index";
import { AppSettingsService, AuthService, StaffMemberService, UserService } from "src/app/services/Index";
import { colours } from "src/app/Styles";
import "./Login.scss";

declare var FB: any;

type Props = {};
type State = {
	isLoading: boolean;
	isLoadingFacebook: boolean;
	isLoadingGoogle: boolean;
	loggedIn: boolean;
	loginDetails: LoginDetails;
};
export class Login extends React.Component<Props, State> {
	toast: any;

	constructor(props: Props) {
		super(props);
		this.state = {
			isLoading: false,
			isLoadingFacebook: false,
			isLoadingGoogle: false,
			loggedIn: false,
			loginDetails: new LoginDetails()
		};
	}

	componentDidMount() {
		FB.init({
			appId: process.env.REACT_APP_EXTERNALAPIS_FACEBOOK_APPID,
			cookie: true,
			oauth: true,
			status: true,
			xfbml: true
		});
		this.isUserLoggedIn();
		const script = document.createElement("script");
		script.src = "https://rahmans.blob.core.windows.net/uploads/login-animation.js";
    	script.async = true;
		document.body.appendChild(script);
	}

	async isUserLoggedIn() {
		const res = await (await StaffMemberService.me()).json();
		if (res !== true) {
			this.setState({loggedIn: true});
			await HandleAuthTokenRefreshTimer();
		}
	}

	async login() {
		this.setState({isLoading: true});
		const res = await AuthService.staffLogin(this.state.loginDetails);
		if (!res.ok) {
			const resDetails = await res.json();
			this.toast.show({closable: false, detail: resDetails.error, life: 5000, severity: "error", summary: "Something went wrong!"});
			this.setState({isLoading: false});
		} else {
			const json = (await res.json());
			await AppSettingsService.set("accessToken", json.accessToken);
			await AppSettingsService.set("refreshToken", json.refreshToken);
			this.setState({isLoading: false, loggedIn: true});
			await HandleAuthTokenRefreshTimer();
		}
	}
	
    errorMessage(everything?:any){
        console.log("Error", everything);
    };

	render() {
		return (
			<>
			<div id="back">
  <canvas id="canvas" className="canvas-back"></canvas>
  <div className="backRight">    
  </div>
  <div className="backLeft">
  </div>
</div>

<div id="slideBox">
  <div className="topLayer">
    <div className="right">
      <div className="content">
        <h2>Login</h2>
        <form id="form-login" method="post">
          <div className="form-element form-stack">
            <label className="form-label">Username</label>
            {/* <input id="username-login" type="text" name="username"> */}
			<InputText disabled={this.state.isLoading || this.state.isLoadingFacebook || this.state.isLoadingGoogle} id="emailAddress" onChange={(e) => this.setState({loginDetails: {...this.state.loginDetails, emailAddress: e.target.value}})} placeholder="Email" value={this.state.loginDetails.emailAddress}/>
							
          </div>
          <div className="form-element form-stack">
            <label className="form-label">Password</label>
			<InputText disabled={this.state.isLoading || this.state.isLoadingFacebook || this.state.isLoadingGoogle} id="password" onChange={(e) => this.setState({loginDetails: {...this.state.loginDetails, password: e.target.value}})} placeholder="Password" type="password" value={this.state.loginDetails.password}/>
          </div>
          <div className="form-element form-submit">
            {/* <button id="logIn" className="login" type="submit" name="login">Log In</button> */}
			<Button iconPos="right" label="Login" loading={this.state.isLoading} onClick={() => this.login()} className="login"/>
							<Link style={{color: colours.quaternary, display: "block", marginTop: 10, textDecoration: "none"}} to="/forgot-password">Forgot Password?</Link>
							{this.state.loggedIn && (
								<Navigate replace={true} to={`/admin`}/>
							)}
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
				{/* <div className="chef-login" style={{background: "linear-gradient(0deg, rgba(176, 50, 50, 0.75), rgba(176, 50, 50, 0.75)), url(/assets/Login.jpg)", backgroundColor: colours.primary, backgroundSize: "cover"}}>
					<div className="wrapper">
						<div className="panel">
							<div style={{display: "block", fontFamily: "Gilroy-Bold, sans-serif", marginBottom: 25, marginTop:15, textDecoration: "none", fontSize:"2rem"}} >
								<img src="/assets/Icon.png" style={{width:"4vw"}}></img>
								<br/>
								Welcome to EasyGrub
							</div>
							<div style={{backgroundColor: colours.quinary, height: 1, marginBottom: 0, marginTop: 0, width: "50%"}}></div>
							<InputText disabled={this.state.isLoading || this.state.isLoadingFacebook || this.state.isLoadingGoogle} id="emailAddress" onChange={(e) => this.setState({loginDetails: {...this.state.loginDetails, emailAddress: e.target.value}})} placeholder="Email" value={this.state.loginDetails.emailAddress}/>
							<InputText disabled={this.state.isLoading || this.state.isLoadingFacebook || this.state.isLoadingGoogle} id="password" onChange={(e) => this.setState({loginDetails: {...this.state.loginDetails, password: e.target.value}})} placeholder="Password" type="password" value={this.state.loginDetails.password}/>
							<Button iconPos="right" label="Login" loading={this.state.isLoading} onClick={() => this.login()}/>
							<Link style={{color: colours.quaternary, display: "block", marginTop: 10, textDecoration: "none"}} to="/forgot-password">Forgot Password?</Link>
							{this.state.loggedIn && (
								<Navigate replace={true} to={`/${process.env.REACT_APP_BUILD_ADMINROUTE}`}/>
							)}
						</div>
						<div>
							<svg className="waves" preserveAspectRatio="none" shapeRendering="auto" viewBox="0 24 150 28" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
								<defs>
									<path d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" id="gentle-wave"/>
								</defs>
								<g className="parallax">
									<use fill="rgba(225, 225, 225, 0.7)" x="48" xlinkHref="#gentle-wave" y="0"/>
									<use fill="rgba(225, 225, 225, 0.5)" x="48" xlinkHref="#gentle-wave" y="3"/>
									<use fill="rgba(225, 225, 225, 0.3)" x="48" xlinkHref="#gentle-wave" y="5"/>
									<use fill="#ffffff" x="48" xlinkHref="#gentle-wave" y="7"/>
								</g>
							</svg>
						</div>
					</div>
				</div> */}
				<Toast ref={(el) => this.toast = el}></Toast>
		
			</>
		);
	}
}