import { Guid } from "guid-typescript";
import { FetchInterceptor } from "../helpers/Index";
import { AddDishToCart, Order } from "../interfaces/Index";

import { Service } from "./Service";
import { AdjustItemQuantity } from "../interfaces/AdjustItemQuantity";
import { AddDiscountCodeToOrder } from "../interfaces/AddDiscountCodeToOrder";
import { ChangeOrderType } from "../interfaces/ChangeOrderType";
import { SetContactDetails } from "../interfaces/SetContactDetails";
import { SetOrderPaymentMethod } from "../interfaces/SetOrderPaymentMethod";
import { ChangeOrderStatus } from "../interfaces/ChangeOrderStatus";
import { AddOldOrderToOrder } from "../interfaces/AddOldOrderToOrder";

const endpoint = "Order";
export const OrderService = {
	endpoint,
	
	addDiscountCode: async (data: AddDiscountCodeToOrder) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/AddDiscountCode`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	addItem: async (data: AddDishToCart) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/AddItem`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	addOldOrder: async (data: AddOldOrderToOrder) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/AddOldOrder`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	adjustItemQuantity: async (data: AdjustItemQuantity) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/AdjustItemQuantity`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	changeOrderStatus: async (data: ChangeOrderStatus) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/ChangeStatus`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	changeOrderType: async (data: ChangeOrderType) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/ChangeOrderType`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	createSingle: async (data: Order) => await Service.createSingle(endpoint, data),
	deleteSingleById: async (id: number) => await Service.deleteSingleById(endpoint, id),
	getAll: async () => await Service.getAll(endpoint),
	getSingleById: async (id: number) => await Service.getSingleById(endpoint, id),
	getSingleByLookupId: async (id: Guid) => await Service.getSingleByLookupId(endpoint, id),
	mine: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Mine`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	removeDiscountCode: async (data: AddDiscountCodeToOrder) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/RemoveDiscountCode`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	removeItem: async (data: AdjustItemQuantity) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/RemoveItem`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	setContactDetails: async (data: SetContactDetails) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/SetContactDetails`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	setPaymentMethod: async (data: SetOrderPaymentMethod) => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/SetPaymentMethod`, {body: JSON.stringify(data), headers: {"Content-Type": "application/json"}, method: "POST"}),
	sevenDays: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/7Days`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	today: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/Today`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	thirtyDays: async () => await FetchInterceptor(`${process.env.REACT_APP_SERVER_APIURL}/${endpoint}/30Days`, {headers: {"Content-Type": "application/json"}, method: "GET"}),
	updateSingleById: async (id: number, data: Order) => await Service.updateSingleById(endpoint, id, data)
}