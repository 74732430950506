export enum AllowedOrderTypes {
        /// <summary>
        /// Allow Collection
        /// </summary>
        CollectionOnly = 0,
        /// <summary>
        /// Allow Delivery
        /// </summary>
        DeliveryOnly = 10,
        /// <summary>
        /// Allow both
        /// </summary>
        CollectionOrDelivery = 20,
}